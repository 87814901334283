import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, DialogActions, Typography, Button } from '@material-ui/core';
import { Modal } from '@shared/components/modals/Modal';

interface ConfirmationModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  title?: string;
  customPaddingContent?: string;
  customPaddingTitle?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onConfirm,
  onCancel,
  message,
  title = 'Confirm Navigation',
  customPaddingContent,
  customPaddingTitle
}) => {
  const classes = useStyles({ customPaddingContent, customPaddingTitle });

  return (
    <Modal maxWidth='sm' open={open} onClose={onCancel} title={title}>
      <Grid container>
        <Typography>{message}</Typography>
      </Grid>
      <DialogActions className={classes.actions}>
        <Button onClick={onCancel} variant='contained' className={classes.cancelButton}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant='contained' className={classes.confirmButton} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Modal>
  );
};

const useStyles = makeStyles<Theme, { customPaddingContent?: string; customPaddingTitle?: string }>((theme: Theme) => ({
  actions: {
    marginTop: '1rem'
  },
  cancelButton: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));
