import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Components
import TextField from '@material-ui/core/TextField';
// helpers
import { get } from 'lodash';
// Types
import { FormikHandlers, FieldMetaProps, FormikErrors } from 'formik';

interface IHoursCellProps extends FormikHandlers, FieldMetaProps<any> {
  cell: { value: any };
  row: { index: number };
  column: { id: string };
  data: any[];
  updateMyData: (rowIndex: number, columnId: string, value: any) => void;
  errors: FormikErrors<any>;
}

const inputProps = {
  step: 0.25,
  min: 0
};

const useStyles = makeStyles(theme => ({
  numeric: {
    '& input': {
      textAlign: 'right'
    }
  }
}));

export const HoursCell: FC<IHoursCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  data,
  updateMyData,
  errors,
  touched,
  handleBlur
}) => {
  const classes = useStyles();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // DON'T UPDATE HERE, OR WE WILL RENDER ON EVERY KEYSTROKE
  const onChange = ({ target: { value } }: any) => {
    setValue(value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e: any) => {
    updateMyData(index, id, value ? Number(value).toFixed(2) : '0.00');
    handleBlur(e);
  };

  useEffect(() => {
    if (!Number(value) && get(errors, `timeEntries[${index}].${id}`) && get(touched, `timeEntries[${index}].${id}`)) {
      setValue('');
    }
  }, [errors, id, index, touched, value]);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(initialValue ? Number(initialValue).toFixed(2) : '0.00');
  }, [initialValue]);

  return (
    <TextField
      fullWidth
      type='number'
      className={classes.numeric}
      key={`${id}-search-text-field-${index}`}
      onFocus={({ target }) => target.select()}
      id={`${id}-search-text-field-${index}`}
      value={value}
      disabled={data[index].isLocked}
      name={`timeEntries[${index}].${id}`}
      error={!value && get(errors, `timeEntries[${index}].${id}`) && get(touched, `timeEntries[${index}].${id}`)}
      helperText={
        !value &&
        get(errors, `timeEntries[${index}].${id}`) &&
        get(touched, `timeEntries[${index}].${id}`) &&
        get(errors, `timeEntries[${index}].${id}`)
      }
      onBlur={onBlur}
      onChange={onChange}
      inputProps={inputProps}
      FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
    />
  );
};
