import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Components
import List from '@material-ui/core/List';
import { MobileNavItem } from './MobileNavItem';
import { MobileClientNavItem } from './MobileClientNavItem';
import { ClientSwitcher } from '@shared/components/switcher';
// Types
import { IUserProps, IAppState } from '@shared/types';
// Helpers
import { Navigation } from '@shared/helpers';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IMobileNavProps {
  onNav?: (event: {}) => void;
}

export const MobileNav: FC<IMobileNavProps> = ({ onNav }) => {
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const classes = useStyles();
  const { userAccess, featureFlags } = user;
  const history = useHistory();
  const pendingRequests = !!featureFlags.find(flag => flag.name === 'client-backlog-request')?.hasAccess;
  const clientReleases = !!featureFlags.find(flag => flag.name === 'mymwks-client-release-notes')?.hasAccess;
  const accessSoftwareReleases = !!featureFlags.find(flag => flag.name === 'software-release')?.hasAccess;
  const sharedFilesUpdate = !!featureFlags.find(flag => flag.name === 'shared-files-update')?.hasAccess;
  const hasZendeskForm = !!featureFlags.find(flag => flag.name === 'zendesk-form')?.hasAccess;

  const clientMenu = Navigation && Navigation.find(item => item.title === 'Clients');

  const userHasAccess = (item: any) => {
    return !item.userAccess || userAccess[item.userAccess];
  };

  const navItemShouldBeInMenu = (item: any) => {
    if (item?.title === 'Software Releases' && !accessSoftwareReleases) {
      return false;
    }
    return userHasAccess(item);
  };

  const clientNavItemShouldBeInMenu = (item: any) => {
    if (!pendingRequests && item?.title === 'Pending Requests') {
      return false;
    }
    if (!clientReleases && item?.title === 'Releases') {
      return false;
    }
    if (!sharedFilesUpdate && item?.title === 'Files V2') {
      return false;
    }
    if (!hasZendeskForm && item?.title === 'Zendesk Form') {
      return false;
    }
    return userHasAccess(item);
  };

  return (
    <List disablePadding className={classes.hideForPrint}>
      {history.location.pathname.includes('clients') && <ClientSwitcher />}
      {user && userAccess.ClientOnly
        ? clientMenu &&
          clientMenu.subNav &&
          clientMenu.subNav
            .filter(clientNavItemShouldBeInMenu)
            .map(item => <MobileClientNavItem key={`item-${item.title}`} {...item} onNav={onNav} />)
        : user && user.userAccess && Object.keys(user.userAccess).length
        ? Navigation.filter(navItemShouldBeInMenu).map(navItem =>
            (!navItem.userAccess || userAccess[navItem.userAccess]) && navItem.subNav ? (
              <MobileNavItem key={`mobile-nav-item-${navItem.title}`} {...navItem} onNav={onNav} />
            ) : null
          )
        : null}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  hideForPrint: {
    '@media print': {
      display: 'none'
    }
  }
}));
