import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { Typography, TextField } from '@material-ui/core';
// Types
import { IRateHistory } from '@shared/types';

interface IHistoryRateCellProps {
  cell: { value: any };
  row: { index: number };
  data: IRateHistory[];
  editing: number;
  updateMyData: (index: number, newData: { rate?: number; effectiveDate?: string }) => void;
}

export const HistoryRateCell: FC<IHistoryRateCellProps> = ({ cell: { value: initialValue }, row: { index }, data, editing, updateMyData }) => {
  const classes = useStyles();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<number>(initialValue);

  // DON'T UPDATE HERE, OR WE WILL RENDER ON EVERY KEYSTROKE
  const onChange = ({ target: { value } }: any) => {
    setValue(value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e: any) => {
    updateMyData(index, { rate: value });
  };

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // reset when editing is false
  useEffect(() => {
    if (editing !== index) {
      setValue(data[index].rate);
    }
  }, [editing, data, index]);

  return editing === index ? (
    <TextField
      className={classes.textField}
      value={value}
      onFocus={({ target }) => target.select()}
      type='number'
      onChange={onChange}
      onBlur={onBlur}
    />
  ) : (
    <Typography variant='body1'>${value}</Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& input': {
      textAlign: 'end'
    }
  }
}));
