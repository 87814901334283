import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as localForage from 'localforage';
// redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import { ProgressReportDetail } from '../components/ProgressReportDetail';
import { Loader } from '@shared/components/loader';
// fetch
import { getSingleProgressReportByReportId, getSingleProgressReportSprintAccomplishments, getSingleProgressReportSprintPlans } from '@shared/fetch';
// redux
import { setExtranetClient, setCurrentReport } from '@shared/redux/actions';
// types
import { IAppState, IUserProps, IProgressReportDetail } from '@shared/types';
// constants
import { selectedClientIdLSKey, userAccess } from '@shared/constants';

export const ProgressReportEdit = () => {
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const [apiReport, setApiReport] = useState<IProgressReportDetail | null>(null);
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const isAdmin = user.userAccess[userAccess.VIEW_ADMIN];
  const hasClientFinancialAccess = user.userAccess[userAccess.CLIENT_FINANCIALS_DATA];
  // router
  const { reportId }: { reportId: string } = useParams();
  // dispatch
  const dispatch = useDispatch();
  // redux
  const { currentReport } = useSelector((state: IAppState) => state.progressReports);
  // api
  const fetchProgressReport = async () => {
    if (reportId) {
      try {
        const report = await getSingleProgressReportByReportId(Number(reportId), { withBudget: hasClientFinancialAccess });
        let sprintActivities = report.sprintActivities;
        let mercuryNeeds = report.mercuryNeeds;
        if (!report.sprintActivities) {
          let sprint = await getSingleProgressReportSprintAccomplishments(report.clientId, report.projectIterationUuid);
          sprintActivities = sprint.sprintAccomplishments;
        }
        if (!report.mercuryNeeds) {
          mercuryNeeds = await getSingleProgressReportSprintPlans(report.clientId, report.projectIterationUuid);
        }
        if (currentReport && currentReport.isPreview) {
          setApiReport({
            ...report,
            sprintActivities,
            mercuryNeeds
          });
        }
        // don't reset redux state if you have been looking at preview of a report
        if (!currentReport || (currentReport && !currentReport.isPreview)) {
          dispatch(
            setCurrentReport({
              ...report,
              sprintActivities,
              mercuryNeeds
            })
          );
        }
        // set local forage with the correct clientId
        localForage.setItem(selectedClientIdLSKey, report.clientId);
        dispatch(
          setExtranetClient({
            ...selectedClient,
            clientId: report.clientId,
            name: report.clientName
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  // effects
  useEffect(() => {
    if (isAdmin) {
      fetchProgressReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!currentReport && isAdmin && <Loader position='centered' />}
      {currentReport && isAdmin && <ProgressReportDetail currentReport={currentReport} isEditing apiReport={apiReport} />}
      {!isAdmin && <>Unable to access, please login as Admin to edit a progress report.</>}
    </>
  );
};
