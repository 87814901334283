import React, { useState, useEffect, FC } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
// Components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { Loader } from '@shared/components/loader';
// Types
import { IExtranetSprintItem, IAppState, IExtranetSprintList } from '@shared/types';
// Fetch
import { getExtranetSprintList } from '@shared/fetch';
// helpers
import { useSelector } from 'react-redux';

interface ISprintSwitcherProps {
  disablePortal?: boolean;
  handleSelectedSprint?: (sprint: IExtranetSprintItem) => void;
  handleSprintOptions?: (sprints: IExtranetSprintList) => void;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  showArrows?: boolean;
  showBacklogButton?: boolean;
  sprintOption?: IExtranetSprintItem;
  tooltip?: string;
  excludeJogs?: boolean;
  showCurrentSprint?: boolean;
  showProgressReportSprint?: boolean;
  checkLatestReport?: number | null;
}

export const SprintSwitcher: FC<ISprintSwitcherProps> = ({
  disablePortal,
  handleSelectedSprint,
  handleSprintOptions,
  isDisabled,
  isFullWidth,
  showBacklogButton,
  showArrows = true,
  sprintOption,
  tooltip,
  excludeJogs,
  showProgressReportSprint = false,
  checkLatestReport
}) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState<IExtranetSprintItem | undefined>(sprintOption);
  const [sprintOptions, setSprintOptions] = useState<IExtranetSprintItem[]>([]);

  const fetchSprints = async () => {
    if (!isLoading) {
      setLoading(true);
    }
    try {
      const res = await getExtranetSprintList({ excludeJogs: !!excludeJogs, checkLatestReport: checkLatestReport });
      setSprintOptions(res.sprints);
      handleSprintOptions && handleSprintOptions(res);
      if (!sprintOption) {
        handleSelectedSprint && handleSelectedSprint(showProgressReportSprint ? res.nextProgressReportSprint : res.currentSprint);
        setSelectedSprint(showProgressReportSprint ? res.nextProgressReportSprint : res.currentSprint);
      }
    } catch (error) {
      console.error('fetchSprints', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isDisabled) {
      fetchSprints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if the parent option forces the change in this component
  useEffect(() => {
    if (sprintOption) {
      setSelectedSprint(sprintOption);
    }
  }, [sprintOption]);

  const isOpen = Boolean(anchorEl);
  const classes = sprintSwitcherStyles({ isFullWidth });
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const gaLabelClientValue: string = selectedClient ? ` | Client: ${selectedClient?.name}` : '';
  return (
    <Grid container alignContent='center' className={classes.switcherWrapper}>
      {showArrows && (
        <IconButton
          disabled={
            (selectedSprint && selectedSprint.name === 'Backlog') ||
            (selectedSprint && sprintOptions && sprintOptions[0] ? selectedSprint.name === sprintOptions[0].name : false) ||
            isLoading ||
            false
          }
          onClick={() => {
            const selectedIndex = sprintOptions?.findIndex(option => option.name === selectedSprint?.name);
            ReactGA.event({
              category: 'User Stories',
              action: 'Switch Sprints',
              value: selectedIndex,
              label: `Switch to Selected Sprint: ${selectedSprint?.name}${gaLabelClientValue}`
            });
            setSelectedSprint(sprintOptions[selectedIndex - 1]);
            handleSelectedSprint && handleSelectedSprint(sprintOptions[selectedIndex - 1]);
          }}
          className={classes.arrowButton}
        >
          <ChevronLeft />
        </IconButton>
      )}
      <Tooltip arrow={true} placement='top' title={tooltip || ''}>
        <span className={classes.selectButtonWrapper}>
          <Button
            endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            disableRipple
            disabled={isLoading || isDisabled}
            aria-describedby='sprint-switcher'
            onClick={e => {
              setAnchorEl(e.currentTarget);
            }}
            className={classes.selectButton}
          >
            {isLoading && <Loader size='medium' position='centered' />}
            {!isLoading && <span>{selectedSprint?.name || 'Select a Sprint'}</span>}
          </Button>
        </span>
      </Tooltip>
      <Popper
        id={isOpen ? 'sprint-switcher' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
        className={classes.popper}
        disablePortal={disablePortal}
      >
        <Autocomplete
          id='sprint-switcher'
          open
          onClose={() => {
            if (anchorEl) {
              anchorEl.focus();
            }
            setAnchorEl(null);
          }}
          disableCloseOnSelect
          disablePortal
          options={sprintOptions}
          noOptionsText='No Sprints'
          onChange={(e: any, newValue: IExtranetSprintItem | null) => {
            if (newValue) {
              ReactGA.event({
                category: 'User Stories',
                action: 'Switch Sprints',
                label: `Switch to Selected Sprint: ${newValue.sprintName}${gaLabelClientValue}`
              });
              setSelectedSprint(newValue);
              handleSelectedSprint && handleSelectedSprint(newValue);
            }
            if (anchorEl) {
              anchorEl.focus();
            }
            setAnchorEl(null);
          }}
          classes={{
            option: classes.option
          }}
          getOptionLabel={(option: IExtranetSprintItem) => option.name}
          renderOption={(option: IExtranetSprintItem) => {
            const selectedIndex = sprintOptions?.findIndex(option => option.name === selectedSprint?.name);
            // hack here to show the correct background color for selected option in the list
            setTimeout(() => {
              const optionEl = document.getElementById(`sprint-switcher-option-${selectedIndex}`);
              if (optionEl) {
                optionEl.setAttribute('aria-selected', 'true');
              }
            }, 1);
            let type = 'Current';
            if (isBefore(new Date().setHours(0, 0, 0, 0), new Date(option.startDate as string))) {
              type = 'Future';
            }
            if (isAfter(new Date().setHours(0, 0, 0, 0), new Date(option.endDate as string))) {
              type = 'Past';
            }
            return (
              <Grid container alignItems='center' justify='space-between'>
                <span>{option.name}</span>
                <Typography variant='caption'>{type}</Typography>
              </Grid>
            );
          }}
          renderInput={params => (
            <>
              <InputBase
                className={classes.inputBase}
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder='Find sprints...'
              />
              {showBacklogButton && (
                <Button
                  disableRipple
                  className={classes.backlogButton}
                  disabled={(selectedSprint && selectedSprint.name === 'Backlog') || false}
                  onClick={e => {
                    setAnchorEl(null);
                    handleSelectedSprint &&
                      handleSelectedSprint({
                        name: 'Backlog',
                        projectIterationUuid: null
                      });
                    setSelectedSprint({
                      name: 'Backlog',
                      projectIterationUuid: null
                    });
                  }}
                >
                  Backlog
                </Button>
              )}
            </>
          )}
        />
      </Popper>
      {showArrows && (
        <IconButton
          className={classes.arrowButton}
          disabled={
            (selectedSprint && selectedSprint.name === 'Backlog') ||
            (selectedSprint && sprintOptions && sprintOptions[sprintOptions.length - 1]
              ? selectedSprint.name === sprintOptions[sprintOptions.length - 1].name
              : false) ||
            isLoading ||
            false
          }
          onClick={() => {
            const selectedIndex = sprintOptions?.findIndex(option => option.name === selectedSprint?.name);
            ReactGA.event({
              category: 'User Stories',
              action: 'Switch Sprints',
              value: selectedIndex,
              label: `Switch to Selected Sprint: ${selectedSprint?.name}${gaLabelClientValue}`
            });
            setSelectedSprint(sprintOptions[selectedIndex + 1]);
            handleSelectedSprint && handleSelectedSprint(sprintOptions[selectedIndex + 1]);
          }}
        >
          <ChevronRight />
        </IconButton>
      )}
    </Grid>
  );
};

const sprintSwitcherStyles = makeStyles((theme: Theme) => ({
  backlogButton: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textTransform: 'none',
    ':hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  switcherWrapper: {
    width: '100%',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  arrowButton: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex'
    }
  },
  selectButtonWrapper: ({ isFullWidth }: { isFullWidth?: boolean }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: isFullWidth ? '100%' : 'auto'
    }
  }),
  selectButton: ({ isFullWidth }: { isFullWidth?: boolean }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: isFullWidth ? '100%' : 335
    },
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '9px 32px',
    fontSize: 14,
    textTransform: 'none'
  }),
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 12,
      '&:focus': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  popper: ({ isFullWidth }: { isFullWidth?: boolean }) => ({
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: isFullWidth ? 'calc(100% - 48px)' : 335,
    zIndex: 10,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa'
  }),
  option: {
    fontSize: 14,
    color: theme.palette.primary.main,
    '&[aria-selected="true"]': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main
    },
    '&[data-focus="true"]': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main
    }
  }
}));
