import { WorkItemStatusLabels } from '@shared/types';

/**
 * Escape characters for exporting to a CSV file
 * @param cell string
 * @returns string
 */
export function sanitizeString(cell: string | null | undefined): string {
  // check if string is empty, null, or undefined
  if (cell) {
    // escape double quotes
    if (cell.indexOf('"') !== -1) {
      cell = cell.replace(/"/g, '""');
    }
    // escape commas
    if (cell.match(/"|,/)) {
      cell = '"' + cell + '"';
    }
    // replace new line with space
    if (cell.indexOf('\r\n') !== -1) {
      cell = cell.replace(/\r\n/g, ' ');
    }
    // replace new line with space
    if (cell.indexOf('\n') !== -1) {
      cell = cell.replace(/\n/g, ' ');
    }
    // replace new line with space
    if (cell.indexOf('\r') !== -1) {
      cell = cell.replace(/\r/g, ' ');
    }
    // replace unicode null \u0000 (http://www.endmemo.com/unicode/ascii.php)
    if (cell.indexOf('\0') !== -1) {
      cell = cell.replace(/\0/g, '');
    }
    return cell;
  } else {
    return '';
  }
}

/**
 * Clients should only ever see 'In Progress' when exporting
 * @param workItemState WorkItemStatusLabels
 * @returns WorkItemStatusLabels
 */
export const mapWorkItemStates = (workItemState: WorkItemStatusLabels): WorkItemStatusLabels => {
  return workItemState === 'Demo Ready' ||
    workItemState === 'Committed' ||
    workItemState === 'PR/Review' ||
    workItemState === 'Remediating' ||
    workItemState === 'Test Ready' ||
    workItemState === 'Releasing'
    ? 'In Progress'
    : workItemState;
};

