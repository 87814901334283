import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
// helpers
import { get } from 'lodash';
// Components
import { TextField, GridList, GridListTile, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Types
import { ITimeTrackingEntry, IAppState } from '@shared/types';
import { FormikHandlers, FieldMetaProps, FormikErrors } from 'formik';
import { getClientProjectsList, reduxFetch } from '@shared/fetch';
import { setClientProjectsList } from '@shared/redux/actions';
import { CLIENT_PROJECT_STATUSES } from '@shared/constants';
import { TimeEntrySourceCard } from '@shared/components/modals';

interface IMobileTimeSheetEntry extends FormikHandlers, FieldMetaProps<any> {
  index: number;
  original: ITimeTrackingEntry;
  updateMyData: (rowIndex: number, columnId: string, value: any) => void;
  errors: FormikErrors<any>;
  data: ITimeTrackingEntry[];
  cells: any[];
}

const getValue = (id: number | null, list: any[], key: string) => {
  const find = list.find(x => x[key] === id);
  if (find) {
    return { label: find.name, value: find[key] };
  }
  return null;
};

export const MobileTimeSheetEntry: FC<IMobileTimeSheetEntry> = ({
  index,
  original,
  updateMyData,
  errors,
  touched,
  handleBlur,
  data,
  cells
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [client, setClient] = useState<number | null>(original?.clientId ?? null);
  const [clientProject, setClientProject] = useState<number | null>(original?.clientProjectId ?? null);
  const [descriptionState, setDescriptionState] = useState(original?.description ?? '');
  const [hoursState, setHoursState] = useState<string>(original?.hours ? Number(original.hours).toFixed(2) : '0.00');

  /** @see reduxFetch Fetch up-to-date values for `clientProjectsList` if it is empty. */
  const { clients, clientProjectsList } = useSelector((state: IAppState) => state.clients);
  useEffect(() => {
    reduxFetch(getClientProjectsList, clientProjectsList)
      .then(res => {
        dispatch(setClientProjectsList(res));
      })
      .catch(err => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClient(original?.clientId);
    setClientProject(original?.clientProjectId);
    setDescriptionState(original?.description);
    setHoursState(original?.hours ? Number(original.hours).toFixed(2) : '0.00');
  }, [original]);

  const hasErrors = get(errors, `timeEntries[${index}]`) && get(touched, `timeEntries[${index}]`);

  return (
    <ExpansionPanel className={clsx(classes.root, hasErrors ? classes.errors : '')}>
      <ExpansionPanelSummary
        className={clsx(classes.expansionPanel, original?.isSuggestion ? classes.suggestion : '')}
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div className={classes.panelSummaryWrapper}>
          <Typography component='span'>{descriptionState || 'No Description'}</Typography>
          {original?.isSuggestion && cells[5] && cells[5].render ? <div className={classes.actionHeader}>{cells[5].render('Cell')}</div> : null}
          <Typography component='span'>{hoursState}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <Autocomplete
          id={`client-search-autocomplete-${index}`}
          options={clients.filter(c => c.isActive).map(client => ({ label: client.name, value: client.clientId }))}
          getOptionLabel={option => option && option.label}
          // Default implementation of this fn in MUI v4.x is `(opt, val) => opt === val`
          // Provide an override like so, so we can compare objects which prevents MUI console warnings
          getOptionSelected={(a, b) => a.value === b.value}
          value={getValue(client, clients, 'clientId')}
          onChange={(e: any, value: any) => {
            setClient(value ? value.value : null);
            updateMyData(index, 'clientId', value ? value.value : null);
            updateMyData(index, 'clientProjectId', null);
          }}
          renderInput={params => {
            const inputProps = params.inputProps;
            (inputProps as any).autoComplete = 'off';
            return (
              <TextField
                {...params}
                inputProps={inputProps}
                label='Client'
                name='client'
                error={
                  !getValue(client, clients, 'clientId') &&
                  get(errors, `timeEntries[${index}].clientId`) &&
                  get(touched, `timeEntries[${index}].clientId`)
                }
                onBlur={handleBlur}
                helperText={
                  !getValue(client, clients, 'clientId') &&
                  get(errors, `timeEntries[${index}].clientId`) &&
                  get(touched, `timeEntries[${index}].clientId`) &&
                  get(errors, `timeEntries[${index}].clientId`)
                }
                FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
              />
            );
          }}
        />
        <Autocomplete
          className={classes.marginTop}
          id={`client-search-project-autocomplete-${index}`}
          options={clientProjectsList
            /** Filter down to current client and active only. Sort by `projectName` alphabetically. */
            .filter(project => project.clientId === data[index].clientId && project.projectStatus === CLIENT_PROJECT_STATUSES.APPROVED)
            .map(project => ({
              label: project.projectName,
              value: project.clientProjectId,
              billingTypeId: project.billingTypeId
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
          getOptionLabel={option => option && option.label}
          getOptionSelected={(a, b) => a.value === b.value}
          value={
            Array(clientProjectsList.find(_ => _.clientProjectId === clientProject)).map(found =>
              found ? { label: found.projectName, value: found.clientProjectId } : null
            )[0]
          }
          onChange={(e: any, value: any) => {
            setClientProject(value ? value.value : null);
            updateMyData(index, 'clientProjectId', value ? value.value : null);
            if (value) {
              updateMyData(index, 'billingTypeId', value.billingTypeId);
            }
          }}
          renderInput={params => {
            const inputProps = params.inputProps;
            (inputProps as any).autoComplete = 'off';
            return (
              <TextField
                {...params}
                inputProps={inputProps}
                label='Client Project'
                onBlur={handleBlur}
                name={`timeEntries[${index}].clientProjectId`}
                error={
                  !getValue(clientProject, clientProjectsList, 'clientProjectId') &&
                  get(errors, `timeEntries[${index}].clientProjectId`) &&
                  get(touched, `timeEntries[${index}].clientProjectId`)
                }
                helperText={
                  !getValue(clientProject, clientProjectsList, 'clientProjectId') &&
                  get(errors, `timeEntries[${index}].clientProjectId`) &&
                  get(touched, `timeEntries[${index}].clientProjectId`) &&
                  get(errors, `timeEntries[${index}].clientProjectId`)
                }
                FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
              />
            );
          }}
        />
        <TextField
          fullWidth
          className={classes.marginTop}
          label='Description'
          key={`description-search-text-field-${index}`}
          id={`description-search-text-field-${index}`}
          value={descriptionState}
          name={`timeEntries[${index}].description`}
          error={!descriptionState && get(errors, `timeEntries[${index}].description`) && get(touched, `timeEntries[${index}].description`)}
          helperText={
            !descriptionState &&
            get(errors, `timeEntries[${index}].description`) &&
            get(touched, `timeEntries[${index}].description`) &&
            get(errors, `timeEntries[${index}].description`)
          }
          FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
          onBlur={(e: any) => {
            updateMyData(index, 'description', descriptionState);
            handleBlur(e);
          }}
          onChange={({ target: { value } }) => setDescriptionState(value)}
        />
        <GridList cellHeight='auto' cols={3} spacing={24}>
          <GridListTile cols={1}>
            <TextField
              fullWidth
              className={classes.numeric}
              label='Hours'
              key={`hours-search-text-field-${index}`}
              id={`hours-search-text-field-${index}`}
              onFocus={({ target }) => target.select()}
              value={hoursState}
              type='number'
              name={`timeEntries[${index}].hours`}
              error={!hoursState && get(errors, `timeEntries[${index}].hours`) && get(touched, `timeEntries[${index}].hours`)}
              helperText={
                !hoursState &&
                get(errors, `timeEntries[${index}].hours`) &&
                get(touched, `timeEntries[${index}].hours`) &&
                get(errors, `timeEntries[${index}].hours`)
              }
              onBlur={(e: any) => {
                updateMyData(index, 'hours', hoursState ? Number(hoursState).toFixed(2) : '0.00');
                handleBlur(e);
              }}
              onChange={({ target: { value } }) => setHoursState(value)}
              FormHelperTextProps={{ style: { position: 'absolute', pointerEvents: 'none' } }}
              inputProps={{
                min: 0
              }}
            />
          </GridListTile>
          <GridListTile cols={1} classes={{ tile: classes.sourceBtnContainer }}>
            <Button
              variant='text'
              style={{ color: theme.palette.text.primary, textTransform: 'none' }}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              {original?.sourceType}
            </Button>
            <TimeEntrySourceCard timeEntry={original} isOpen={!!anchorEl} close={() => setAnchorEl(null)} anchorEl={anchorEl} />
          </GridListTile>
          {cells[5] && cells[5].render ? (
            <GridListTile cols={1}>
              <div className={classes.actionButtons}>{cells[5].render('Cell')}</div>
            </GridListTile>
          ) : null}
        </GridList>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%'
  },
  sourceBtnContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actionHeader: {
    marginLeft: 'auto',
    marginRight: 5,
    marginTop: 0
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  numeric: {
    marginTop: theme.spacing(1),
    '& input': {
      textAlign: 'right'
    }
  },
  labelInput: {
    marginTop: theme.spacing(1),
    '& input:disabled': {
      color: theme.typography.body1.color
    }
  },
  actionButtons: {
    marginTop: theme.spacing(2),
    '&  > div': {
      justifyContent: 'flex-end'
    }
  },
  suggestion: {
    backgroundColor: theme.palette.action.selected
  },
  errors: {
    border: `2px solid ${theme.palette.error.main}`
  }
}));
