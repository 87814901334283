/**
 * Retrieves the timezone abbreviation for a given date.
 *
 * @param {Date} date - The date for which to get the timezone abbreviation.
 * @returns {string} The timezone abbreviation (e.g., "PST", "EST"). If the timezone abbreviation cannot be determined, an empty string is returned.
 */
export function getTimezoneAbbreviation(date: Date): string {
  const timeZonePart = Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
    .formatToParts(date)
    .find(part => part.type === 'timeZoneName');
  return timeZonePart ? timeZonePart.value : '';
}
