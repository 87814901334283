import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, IconButton, Box, Button } from '@material-ui/core';
import { ExpandMore, Delete, Edit } from '@material-ui/icons';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import { IRelease, IAppState } from '@shared/types';
import { FormikHandlers, FieldMetaProps } from 'formik';
import { DeleteReleaseModal } from '../../../employees/components/modals/DeleteReleaseModal';
// redux
import { useSelector } from 'react-redux';

interface IMobileClientReleases extends FormikHandlers, FieldMetaProps<any> {
  original: IRelease;
  handleDelete: (releaseId: number) => void;
}

export const MobileClientReleases: FC<IMobileClientReleases> = ({ original, handleDelete }) => {
  const borderColor = getStatusBorderColor('internal testing');
  const classes = useStyles({ borderColor });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [releaseToDelete, setReleaseToDelete] = useState<IRelease | null>(null);
  const { releaseSummary, releaseDate, releaseStatusName, createdOn, actualReleaseEndDate: releaseEndDate, clientName, application } = original;
  const currentUserRole = useSelector((state: IAppState) => state.user);
  const { AdminData, ViewAdmin } = currentUserRole.userAccess;

  const handleExpansionChange = (event: any, expanded: any) => {
    setIsExpanded(expanded);
  };

  const handleDeleteClick = (release: IRelease) => {
    setReleaseToDelete(release);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setReleaseToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (releaseToDelete) {
      handleDelete(releaseToDelete.releaseId);
      setIsDeleteModalOpen(false);
      setReleaseToDelete(null);
    }
  };

  const targetPath =
  original.releaseStatusName === 'New'
    ? `/employees/software-releases/${original.releaseId}/general-information`
    : original.releaseStatusName === 'Client Approval'
    ? `/employees/software-releases/${original.releaseId}/waiting-for-release`
    : `/employees/software-releases/${original.releaseId}/general-information`;

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={isExpanded} onChange={handleExpansionChange}>
        <ExpansionPanelSummary className={classes.expansionPanel} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
          <div className={classes.panelSummaryWrapper}>
            <div className={classes.titleAndLink}>
              <Link
                className={classes.link}
                to={{
                  pathname: targetPath,
                  state: { release: original }
                }}
              >
                <Typography component='span' className={isExpanded ? '' : classes.ellipsis}>
                  {releaseSummary}
                </Typography>
              </Link>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Box className={classes.deleteButtonContainer}>
            <IconButton size='small' aria-label='delete-release' className={classes.deleteButton} onClick={() => handleDeleteClick(original)}>
              <Delete />
            </IconButton>
          </Box>
          <FormLabel component='h2'>REQUEST STATUS</FormLabel>
          <Typography component='span' className={classes.boldTitleCase}>
            {releaseStatusName || '--'}
          </Typography>

          <FormLabel component='h2'>CLIENT</FormLabel>
          <Typography component='span' className={classes.boldTitleCase}>
            {clientName || '--'}
          </Typography>

          <FormLabel component='h2'>APPLICATION</FormLabel>
          <Typography component='span' className={classes.boldTitleCase}>
            {application || '--'}
          </Typography>

          <FormLabel component='h2'>RELEASE DATE</FormLabel>
          <Typography component='span' style={{ fontWeight: 'bold' }}>
            {releaseDate ? format(new Date(releaseDate), 'MM/dd/yy h:mma') : 'TBD'}
          </Typography>

          <FormLabel component='h2'>RELEASE ENDED</FormLabel>
          <Typography component='span' style={{ fontWeight: 'bold' }}>
            {releaseEndDate ? format(new Date(releaseEndDate), 'MM/dd/yy h:mma') : '--'}
          </Typography>

          <FormLabel component='h2'>CREATED ON</FormLabel>
          <Typography component='span' style={{ fontWeight: 'bold' }}>
            {createdOn ? format(new Date(createdOn), 'MM/dd/yy h:mma') : '--'}
          </Typography>

          {AdminData && ViewAdmin ? (
            <Box className='mt-2 text-center'>
              <Link
                to={{
                  pathname: `/employees/software-releases/${original.releaseId}/general-information`,
                  state: { release: original }
                }}
              >
                <Button color='primary' startIcon={<Edit className={classes.editButton} />} className={classes.editButton}>
                  Edit Release
                </Button>
              </Link>
            </Box>
          ) : null}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <DeleteReleaseModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        releaseTitle={releaseToDelete ? releaseToDelete.releaseSummary : ''}
      />
    </>
  );
};

function getStatusBorderColor(status: string): string {
  let statusLower = status.toLowerCase();
  if (statusLower === 'open') {
    return '#c72a1c';
  } else if (statusLower === 'approved') {
    return '#c72a1c';
  } else if (statusLower === 'ready') {
    return '#c72a1c';
  } else if (statusLower === 'awaiting your reply') {
    return '#3091ec';
  } else if (statusLower === 'in progress') {
    return '#c72a1c';
  } else if (statusLower === 'internal testing') {
    return '#3091ec';
  } else {
    return '#87929d';
  }
}

const useStyles = makeStyles<Theme, { borderColor: string }>((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: '#f1f5f9',
    borderLeft: props => `4px solid ${props.borderColor}`,
    '&& .MuiExpansionPanelDetails-root': {
      padding: '0 1.5rem 1.5rem 1.5rem'
    }
  },
  expansionPanel: {
    padding: '0px 24px'
  },
  panelSummaryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 850px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  launchIconLink: {
    verticalAlign: '3px',
    marginLeft: '4px'
  },
  launchIconLinkExpanded: {
    verticalAlign: '-3px',
    marginLeft: '4px'
  },
  launchIcon: {
    fontSize: '16px'
  },
  boldTitleCase: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '@media (max-width: 1100px)': {
      maxWidth: '500px'
    },
    '@media (max-width: 900px)': {
      maxWidth: '450px'
    },
    '@media (max-width: 715px)': {
      maxWidth: '350px'
    },
    '@media (max-width: 600px)': {
      maxWidth: '285px'
    },
    '@media (max-width: 450px)': {
      maxWidth: '220px'
    },
    '@media (max-width: 400px)': {
      maxWidth: '185px'
    }
  },
  titleAndLink: {
    minWidth: '450px',
    '@media (max-width: 650px)': {
      minWidth: '325px'
    },
    '@media (max-width: 450px)': {
      minWidth: '225px'
    },
    '@media (max-width: 400px)': {
      minWidth: '200px'
    }
  },
  deleteButtonContainer: {
    position: 'absolute',
    top: '0.5rem',
    right: theme.spacing(1)
  },
  deleteButton: {
    padding: 0,
    color: theme.palette.error.main,
    '& .MuiSvgIcon-root': {
      fontSize: '1.15rem'
    }
  },
  editButton: {
    padding: 0,
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  }
}));
