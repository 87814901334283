import React, { FC } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  commonChartProps,
  formatMoney,
  truncateLabel,
  desktopChartLegendOptions,
  mobileChartLegendOptions,
  mobileChartBreakpoint,
  mobileLegendOffset
} from '../../../shared/helpers';
import { IBasicObject } from '../../../shared/types';
import { Box } from '@material-ui/core';
import { useMedia } from 'react-use';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IProgressReportAccruedExpensesChartProps {
  data: IBasicObject[];
  count: number;
}
export const ProgressReportAccruedExpensesChart: FC<IProgressReportAccruedExpensesChartProps> = ({ children, data, count }) => {
  const isMobile = useMedia(mobileChartBreakpoint);
  const classes = useStyles();
  return (
    <Box height={isMobile ? mobileLegendOffset(count) : 400} width='100%'>
      {isMobile && (
        <ResponsiveBar
          {...commonChartProps}
          keys={['Expense', 'Budget']}
          groupMode='grouped'
          data={data}
          layout='horizontal'
          margin={{ top: 30, left: 20, bottom: 80, right: 10 }}
          enableGridX={true}
          enableGridY={false}
          tooltip={({ id, value, indexValue }) => (
            <strong>
              {id} - {formatMoney(value, 0)}
              <br />
              {indexValue}
            </strong>
          )}
          axisLeft={null}
          axisBottom={{
            renderTick: ({ textBaseline, value, x, y }) => {
              return (
                <g transform={`translate(${x},${y})`}>
                  <line stroke='rgb(119, 119, 119)' strokeWidth={1.5} y1={0} y2={5} />
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={'end'}
                    dominantBaseline={'central'}
                    transform={'translate(0,10) rotate(-40)'}
                    style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
                  >
                    {formatMoney(value, 0)}
                  </text>
                </g>
              );
            }
          }}
          legends={mobileChartLegendOptions}
        />
      )}
      {!isMobile && (
        <div className={classes.printing}>
          <ResponsiveBar
            {...commonChartProps}
            keys={['Expense', 'Budget']}
            groupMode='grouped'
            data={data}
            padding={0.4}
            margin={{ top: 30, left: 70, bottom: 120, right: 0 }}
            tooltip={({ id, value, indexValue }) => (
              <strong>
                {id} - {formatMoney(value, 0)}
                <br />
                {indexValue}
              </strong>
            )}
            axisLeft={{
              renderTick: ({ textBaseline, value, x, y }) => {
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text alignmentBaseline={textBaseline} textAnchor={'end'} dominantBaseline={'central'}>
                      {formatMoney(value, 0)}
                    </text>
                  </g>
                );
              }
            }}
            axisBottom={{
              renderTick: ({ textBaseline, value, x, y }) => {
                return (
                  <g transform={`translate(${x},${y})`}>
                    <line stroke='rgb(119, 119, 119)' strokeWidth={1.5} y1={0} y2={5} />
                    <text alignmentBaseline={textBaseline} textAnchor={'end'} dominantBaseline={'central'} transform={'rotate(-40)'}>
                      {truncateLabel(value)}
                    </text>
                  </g>
                );
              }
            }}
            legends={desktopChartLegendOptions}
          />
        </div>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  printing: {
    height: '100%',
    '@media print': {
      maxHeight: '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }
}));
