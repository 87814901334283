import React, { FC, useState, useEffect } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';

import { getClientContacts } from '@shared/fetch';
import { IClientContact } from '@shared/types';

import { DashboardCard } from './DashboardCard';
import { theme } from '@shared/helpers/theme';

interface IClientContactsInfo {
  clientId: number | undefined;
  loading: boolean;
}

const ContactItem: FC<IClientContact> = ({ name, role, email, imageSrc }) => {
  const classes = ClientContactStyles();
  const [srcErrored, setSrcErrored] = useState<boolean>(false);

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} direction='row' alignItems='center' justify='flex-start'>
        <Grid item xs={3}>
          {srcErrored ? (
            <AccountCircle className={classes.accountIcon} />
          ) : (
            <img src={imageSrc} onError={() => setSrcErrored(true)} alt={name} className={classes.roundImage} />
          )}
        </Grid>
        <Grid item xs={9}>
          <Typography variant='h4' component='h3' color='primary' align='left' className={classes.nameText}>
            {name}
          </Typography>
          <Typography variant='subtitle1' component='p' align='left' className={classes.roleText}>
            {role}
          </Typography>
          <Typography align='left' variant='body1' component='p'>
            <Link href={`mailto:${email}`} target='_blank' className={classes.emailLink}>
              {email}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ClientContacts: FC<IClientContactsInfo> = ({ clientId, loading }) => {
  const [clientContacts, setClientContacts] = useState<IClientContact[]>([]);

  useEffect(() => {
    const fetchClientContacts = async () => {
      if (clientId) {
        try {
          const contacts = await getClientContacts(clientId);
          setClientContacts(contacts);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchClientContacts();
  }, [clientId]);

  return (
    <DashboardCard title={'Key Contacts'} color={theme.palette.primary.main}>
      <Grid item xs={12}>
        <Grid direction='column' container alignItems='flex-start' justify='center' className='MuiGrid-spacing-xs-1-5'>
          {loading ? (
            <Grid direction='row' container spacing={1} alignItems='center' justify='space-between' item xs={12}>
              <Grid item xs={12}>
                <Skeleton variant='rect' height={56} width={290} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant='rect' height={56} width={290} />
              </Grid>
            </Grid>
          ) : (
            <>
              {clientContacts.map(({ name, email, role, imageSrc }, index) => (
                <ContactItem key={name + index} name={name} email={email} role={role} imageSrc={imageSrc} />
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

const ClientContactStyles = makeStyles((theme: Theme) => ({
  roundImage: {
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    marginRight: theme.spacing(0.5)
  },
  accountIcon: {
    height: '50px',
    width: '50px',
    color: theme.palette.primary.main
  },
  nameText: {
    color: theme.palette.common.black,
    lineHeight: 1.2
  },
  roleText: {
    color: '#73C26E'
  },
  emailLink: {
    color: theme.palette.primary.main,
    lineHeight: 1.2,
    marginTop: theme.spacing(0.25),
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5)
  }
}));
