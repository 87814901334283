import React, { useState, useEffect, FC, useCallback } from 'react';
import { ThumbUp, DeleteForever } from '@material-ui/icons';
import { Button, Theme, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useMedia } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { debounce } from 'lodash';
// types
import { PortfolioSortOptions, ASC_SORT, DESC_SORT, IExtranetUserRequest, IAppState } from '@shared/types';
// components
import { Toast } from '@shared/components/toast';
import { Table, ITableColumn } from '@shared/components/tables';
import { SearchInput, DateRangePicker, DateRange } from '@shared/components/inputs';
import { Page } from '@shared/components/layout';
import { MobileUserRequest } from '../components/mobile';
import { MobileExpanderLoader } from '@shared/components/loader';
import { ExternalUser } from '../components/modals';
import { ManageUsersNav } from '../components/controls';
import { Pagination } from '@shared/components/pagination';
// constants
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@shared/constants';
// fetch
import { getAccessRequests, deleteExtranetUserRequest } from '@shared/fetch';
// helpers
import { formatShortFriendlyDateWithTime } from '@shared/helpers';
// redux
import { useSelector } from 'react-redux';

interface IManageUsersRequests {
  clientView: boolean;
}

/**
 * Page for approving or deleting external user requests
 */
export const ManageUserRequests: FC<IManageUsersRequests> = ({ clientView = false }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  // check for search query param
  const query = window.location && window.location.search && window.location.search.split('=') && window.location.search.split('=')[1];
  const isDesktop = useMedia('(min-width: 960px)');

  const extranetClient = useSelector((state: IAppState) => state.extranet.selectedClient);

  const [inputValue, setInputValue] = useState<string>(query || '');
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isPageLoading, setPageLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [perPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [currentUser, setCurrentUser] = useState<IExtranetUserRequest | null>(null);
  const [isExternalUserModalOpen, setExternalUserModalOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(query || '');
  const [dateRange, setDateRange] = useState<DateRange>();
  const [displayingSearchResults, setDisplayingSearchResults] = useState<boolean>(false);
  const [accessRequests, setAccessRequests] = useState<IExtranetUserRequest[]>([]);
  const [sortDirection, setSortDirection] = useState<{
    FirstName?: PortfolioSortOptions;
    LastName?: PortfolioSortOptions;
    Email?: PortfolioSortOptions;
    RequestDate?: PortfolioSortOptions;
  }>({
    FirstName: ASC_SORT
  });
  const [selectedSort, setSelectedSort] = useState<string>('FirstName');

  // Debounce the search term update
  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 1000),
    []
  );

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debouncedSetSearchTerm(value);
  };

  const getRequests = async () => {
    setPageLoading(true);
    try {
      const res = await getAccessRequests({
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        startDate: dateRange && dateRange.begin ? format(new Date(dateRange.begin), 'MM-dd-yyyy') : null,
        endDate: dateRange && dateRange.end ? format(new Date(dateRange.end), 'MM-dd-yyyy') : null,
        query: searchTerm.length > 0 ? searchTerm : undefined
      });

      setAccessRequests(res.records);
      setRecordCount(res.totalRecordCount);

      if (res.records.length === 0 && page > 0) {
        setPage(0);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPageLoading(false);
      setDisplayingSearchResults(!!searchTerm || !!dateRange || !!query);
    }
  };

  useEffect(() => {
    // Handle fetching of requests when pagination, sorting, or page size changes
    setPage(0);
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, perPage, searchTerm, dateRange]);

  useEffect(() => {
    // Fetch requests when pagination changes without resetting the page
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // effect used to handle searching
  useEffect(() => {
    // user cleared the search
    if (!query && displayingSearchResults) {
      getRequests();
    }
    // user performed a search
    if (query && !displayingSearchResults) {
      getRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, displayingSearchResults]);

  const hasData = accessRequests && accessRequests.length > 0;

  return (
    <>
      <Page
        title='Manage Users'
        setHeight={false}
        flexGrow={false}
        actions={() => <ManageUsersNav clientView={clientView} selectedView='Requests' setExternalUserModalOpen={setExternalUserModalOpen} />}
      >
        <Grid container alignItems='center' className={classes.searchWrapper}>
          <SearchInput
            id='users-search'
            className={classes.filterSelect}
            value={inputValue}
            placeholder='Search Requests'
            isSearching={isPageLoading}
            handleChange={handleInputChange}
            handleSearch={() => {
              history.push(`${location.pathname}?searchTerm=${inputValue}`);
            }}
            handleClearSearch={() => {
              setInputValue('');
              setSearchTerm('');
              history.push(location.pathname);
            }}
            disabled={isPageLoading}
          />

          <DateRangePicker
            className={classes.filterSelect}
            inputVariant='outlined'
            size='small'
            autoOk
            label='Filter by Date'
            value={dateRange}
            onChange={values => setDateRange(values)}
            disabled={isPageLoading}
            format='M/d/yyyy'
            handleClear={() => {
              setDateRange(undefined);
            }}
          />

          {(!!searchTerm || !!dateRange) && (
            <Button
              className={classes.resetLink}
              tabIndex={0}
              aria-label='filter-reset'
              data-testid={'filterReset-button'}
              onClick={() => {
                setInputValue(''); // Clear the search input value
                setSearchTerm(''); // Clear the search term
                setDateRange(undefined); // Clear the date range
                setPage(0); // Reset pagination to the first page
                history.push(location.pathname);
              }}
              startIcon={<Close />}
              disabled={isPageLoading}
            >
              RESET
            </Button>
          )}
        </Grid>
        <Table
          hidePagination
          data={accessRequests}
          columns={
            [
              {
                Header: 'First Name',
                accessor: 'firstName',
                sort: false,
                isServerSorted: selectedSort === 'FirstName',
                isServerSortedDesc: sortDirection.FirstName === DESC_SORT,
                handleClickColumn: () => {
                  // Manually control sort UI since users are sorted
                  // on the server
                  setSelectedSort('FirstName');
                  setSortDirection({
                    ...sortDirection,
                    FirstName: sortDirection.FirstName === ASC_SORT ? DESC_SORT : ASC_SORT
                  });
                }
              },
              {
                Header: 'Last Name',
                accessor: 'lastName',
                sort: false,
                isServerSorted: selectedSort === 'LastName',
                isServerSortedDesc: sortDirection.LastName === DESC_SORT,
                handleClickColumn: () => {
                  // Manually control sort UI since users are sorted
                  // on the server
                  setSelectedSort('LastName');
                  setSortDirection({
                    ...sortDirection,
                    LastName: sortDirection.LastName === ASC_SORT ? DESC_SORT : ASC_SORT
                  });
                }
              },
              {
                Header: 'Email',
                accessor: 'email',
                sort: false,
                isServerSorted: selectedSort === 'Email',
                isServerSortedDesc: sortDirection.Email === DESC_SORT,
                handleClickColumn: () => {
                  // Manually control sort UI since users are sorted
                  // on the server
                  setSelectedSort('Email');
                  setSortDirection({
                    ...sortDirection,
                    Email: sortDirection.Email === ASC_SORT ? DESC_SORT : ASC_SORT
                  });
                }
              },
              {
                Header: 'Request Date/Time',
                id: 'request-date',
                sort: false,
                accessor: 'requestDate',
                Cell: ({
                  cell: {
                    row: { original }
                  }
                }: {
                  cell: { row: { original: IExtranetUserRequest } };
                }) => formatShortFriendlyDateWithTime(original.requestDate),
                overrideWidth: 500,
                isServerSorted: selectedSort === 'RequestDate',
                isServerSortedDesc: sortDirection.RequestDate === DESC_SORT,
                handleClickColumn: () => {
                  // Manually control sort UI since users are sorted
                  // on the server
                  setSelectedSort('RequestDate');
                  setSortDirection({
                    ...sortDirection,
                    RequestDate: sortDirection.RequestDate === ASC_SORT ? DESC_SORT : ASC_SORT
                  });
                }
              },
              {
                Header: ' ',
                id: 'approve-request',
                sort: false,
                hideLoad: true,
                className: classes.actionButton,
                overrideWidth: 100,
                Cell: ({
                  cell: {
                    row: { original }
                  }
                }: {
                  cell: { row: { original: IExtranetUserRequest } };
                }) => {
                  return (
                    <Button
                      color='primary'
                      startIcon={<ThumbUp />}
                      onClick={() => {
                        setExternalUserModalOpen(true);
                        setCurrentUser(original);
                      }}
                    >
                      Approve
                    </Button>
                  );
                }
              },
              {
                Header: ' ',
                id: 'delete-request',
                overrideWidth: 100,
                sort: false,
                hideLoad: true,
                className: classes.deleteButton,
                Cell: ({
                  cell: {
                    row: { original }
                  }
                }: {
                  cell: { row: { original: IExtranetUserRequest } };
                }) => {
                  return (
                    <Button
                      color='inherit'
                      startIcon={<DeleteForever />}
                      onClick={async () => {
                        const result = window.confirm('Are you sure you want to delete this request?');

                        if (result) {
                          try {
                            setDeleting(true);
                            const isDeleted = await deleteExtranetUserRequest(original.accessRequestId);
                            if (isDeleted) {
                              setSuccessMessage('Request Deleted!');
                              getRequests();
                            }
                          } catch (error) {
                            console.log(error);
                            setErrorMessage('Error deleting request, please try again.');
                          } finally {
                            setDeleting(false);
                          }
                        }
                      }}
                    >
                      Delete
                    </Button>
                  );
                }
              }
            ] as ITableColumn[]
          }
          stickyHeader
          isLoading={isPageLoading || isDeleting}
          ResponsiveComponent={MobileUserRequest}
          ResponsiveComponentLoader={MobileExpanderLoader}
          containerClasses={clsx(isDesktop ? classes.desktopTable : classes.mobileTable)}
          noResultsText={dateRange ? 'No Requests match the applied filter.' : 'No requests found.'}
        />
        {!isPageLoading && hasData && !isDeleting && (
          <div className={classes.paginationWrapper}>
            <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
          </div>
        )}
      </Page>
      <ExternalUser
        clientView={clientView}
        currentUser={
          currentUser
            ? {
                id: currentUser.azureObjectId,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                email: currentUser.email,
                isEnabled: true,
                clientName: extranetClient?.name
              }
            : null
        }
        open={isExternalUserModalOpen}
        onClose={() => {
          setExternalUserModalOpen(false);
          setCurrentUser(null);
        }}
        isRequestsPage
        onSave={() => getRequests()}
      />
      <Toast id='reports-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
      <Toast id='reports-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  },
  mobileTable: {
    padding: 0
  },
  desktopTable: {
    paddingLeft: 5,
    paddingRight: 5
  },
  actionButton: {
    padding: 0
  },
  deleteButton: {
    padding: 0,
    color: theme.palette.error.main
  },
  editing: {
    display: 'none'
  },
  loadMoreWrapper: {
    marginTop: theme.spacing(1)
  },
  searchWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  filterSelect: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.up('md')]: {
      width: 300,
      margin: theme.spacing(0.5, 1, 0.5, 0)
    },
    '&& .MuiInputBase-root': {
      width: '100%',
      paddingLeft: theme.spacing(0.5)
    }
  },
  resetLink: {
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: theme.spacing(1.5),
    color: theme.palette.error.main
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  }
}));
