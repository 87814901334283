import { Button, Grid, GridSize, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Pagination } from '@shared/components/pagination';
import { Toast } from '@shared/components/toast';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, userAccess } from '@shared/constants';
import { getProgressReports } from '@shared/fetch';
import { setCurrentReport } from '@shared/redux/actions';
import { DESC_SORT, IAppState, IProgressReport, IUserProps } from '@shared/types';
import { LoadingReportGridCard } from '@src/clients/components/LoadingReportGridCard';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { Page } from '../../shared/components/layout/Page';
import { CreateProgressReportModal } from '../components/CreateProgressReportModal';
import { NoProgressReports } from '../components/NoProgressReports';
import { ReportGridCard } from '../components/ReportGridCard';

export const ProgressReports = () => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [perPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [progressReports, setProgressReports] = useState<IProgressReport[]>([]);
  const [isShowingCreateModal, showModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const dispatch = useDispatch();
  // Determines the number of loading elements based on the set page size, the elements themselves don't matter
  const loadingResultSize = Array.from({ length: perPage }, () => Math.random());

  // styles
  const classes = progressReportsStyles();
  const isMobile = useMedia('(max-width: 960px)');
  const isTablet = useMedia('(max-width: 1500px)');
  const gridBreakpoint: GridSize = isMobile ? 9 : isTablet ? 6 : 4;

  // redux
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);
  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const isAdmin = user.userAccess[userAccess.VIEW_ADMIN];

  // effects
  useEffect(() => {
    const fetchProgressReports = async () => {
      if (!selectedClient || !selectedClient?.usesProgressReports) {
        return;
      }
      setProgressReports([]);
      setLoading(true);
      try {
        const res = await getProgressReports(selectedClient.clientId, {
          page: page + 1,
          perPage,
          sortBy: 'SprintDate',
          sortDirection: DESC_SORT
        });
        setProgressReports(res.records);
        setRecordCount(res.totalRecordCount);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProgressReports();
  }, [selectedClient, page, perPage]);

  const hasData = progressReports && progressReports.length > 0;

  return (
    <>
      <Page
        title='Progress Reports'
        actions={
          isAdmin
            ? () => (
                <Button disableRipple size='small' color='primary' startIcon={<Add />} onClick={() => showModal(true)}>
                  Create New Report
                </Button>
              )
            : undefined
        }
        hideHeader={true}
        overflow
      >
        <div className={classes.wrapper}>
          {/* REPORT GRID CARD  */}
          {!isLoading && hasData && selectedClient?.usesProgressReports && (
            <div className={isMobile ? '' : classes.reportCardGridContainer}>
              <Grid container spacing={1}>
                {progressReports.map((report: IProgressReport) => (
                  <ReportGridCard
                    key={report.clientPortfolioReportId}
                    isMobile={isMobile}
                    gridBreakpoint={gridBreakpoint}
                    // reset the current report in state, we will query the correct based on the id from the ProgressReport page
                    reactRouterLinkOnClick={() => dispatch(setCurrentReport(null))}
                    report={{
                      ...report,
                      title: report.title,
                      linkTo: `/clients/progress-reports/${report.clientPortfolioReportId}`
                    }}
                  >
                    {/* SPRINT CREATION DATE/TIME */}
                    {report.published ? (
                      <Typography className={classes.font}>{format(new Date(report.dateUpdated || report.dateCreated), 'Pp')}</Typography>
                    ) : (
                      <Typography color='error'>Draft: Not Visible to Client</Typography>
                    )}
                  </ReportGridCard>
                ))}
              </Grid>
            </div>
          )}

          {/* NOT CONFIGURED STATE */}
          {selectedClient && !selectedClient.usesProgressReports && (
            <NoProgressReports isAdmin={false} selectedClient={selectedClient} message='This client is not configured for Progress Reports.' />
          )}

          {/* LOADING STATE */}
          {isLoading && selectedClient?.usesProgressReports && (
            <Grid container spacing={1}>
              {loadingResultSize.map(i => (
                <LoadingReportGridCard key={i} isMobile={isMobile} gridBreakpoint={gridBreakpoint} />
              ))}
            </Grid>
          )}

          {/* EMPTY STATE */}
          {!isLoading && progressReports.length === 0 && selectedClient?.usesProgressReports && (
            <NoProgressReports
              isAdmin={isAdmin}
              selectedClient={selectedClient}
              message={
                isAdmin
                  ? 'No Progress Reports currently exist. Click on the Create New Report button below to create a report.'
                  : 'No Progress reports currently exist. Check back soon to see any newly created reports.'
              }
            />
          )}

          {/* PAGINATION */}
          {!isLoading && hasData && selectedClient?.usesProgressReports && (
            <div className={classes.paginationWrapper}>
              <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            </div>
          )}
        </div>

        {/* CREATE NEW REPORT MODAL */}
        <CreateProgressReportModal
          isOpen={isShowingCreateModal}
          handleClose={() => showModal(false)}
          clientId={selectedClient ? selectedClient.clientId : null}
        />
      </Page>
      <Toast id='reports-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
      <Toast id='reports-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
    </>
  );
};

const progressReportsStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  reportCardGridContainer: {
    zIndex: -1
  },
  font: {
    color: theme.palette.text.disabled
  }
}));
