import React from 'react';
import { IProgressReportLaborAccruedExpenses, IProgressReportLaborSprintExpenses, IBasicObject } from '../types';

export const barChartColors = ['#0d5cab', 'RGB(50, 85, 111)', '#72c267'];
export const budgetChartColors = {
  spent: barChartColors[2],
  budget: barChartColors[0],
  invoiced: barChartColors[1],
  budgetSpentAmount: barChartColors[2],
  budgetInvoicedAmount: barChartColors[0],
  amount: barChartColors[0]
}; //Add more colors as needed, if need ever exceeds more than 2 color options on any chart

export const mobileChartBreakpoint = '(max-width: 960px)';

export const commonChartProps = {
  indexBy: 'projectName',
  colors: barChartColors,
  enableLabel: false,
  innerPadding: 3
};

export const commonChartLegendOptions = {
  dataFrom: 'keys',
  anchor: 'bottom',
  direction: 'row',
  justify: false,
  translateX: 0,
  itemsSpacing: 2,
  itemWidth: 100,
  itemHeight: 20,
  itemDirection: 'left-to-right',
  itemOpacity: 0.85,
  symbolSize: 20,
  effects: [
    {
      on: 'hover',
      style: {
        itemOpacity: 1
      }
    }
  ]
};
export const mobileChartLegendOptions: any[] = [
  {
    ...commonChartLegendOptions,
    translateY: 75
  }
];
export const desktopChartLegendOptions: any[] = [
  {
    ...commonChartLegendOptions,
    translateY: 120
  }
];

export const truncateLabel = (label: string): any => {
  const MAX_LINE_LENGTH = 16;
  const MAX_LINES = 2;
  const LENGTH_OF_ELLIPSIS = 3;
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
  const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
  const groupWordsByLength = new RegExp(`([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`, 'gm');
  const splitValues = label.replace(trimWordsOverLength, '$1...').match(groupWordsByLength);
  return splitValues!.slice(0, 2).map((val: any, i: number) => (
    <tspan key={val} dy={12 * i} x={-10} style={{ fontFamily: 'sans-serif', fontSize: '11px' }}>
      {' '}
      {val}{' '}
    </tspan>
  ));
};

export const mobileLegendOffset = (legendItems: number, itemHeight?: number, offset?: number): number => {
  itemHeight = itemHeight ? itemHeight : 60;
  offset = offset ? offset : 100;
  return legendItems * itemHeight + offset;
};

//Sprint Expenses
//Format data so that bar chart can render
export const buildSprintExpensesData = (data: IProgressReportLaborSprintExpenses[]): IBasicObject[] => {
  return data.map(project => ({
    id: project.projectId,
    projectName: project.projectName,
    Expense: project.amount
  }));
};

//Accrued Expenses
//Format data so that bar chart can render as grouped columns
export const buildAccruedExpensesData = (data: IProgressReportLaborAccruedExpenses[]): IBasicObject[] => {
  return data.map(project => ({
    id: project.projectId,
    projectName: project.projectName,
    Budget: project.budgetedAmount,
    Expense: project.amount
  }));
};
