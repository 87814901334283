import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import moment from 'moment-timezone';
// Types
import { WorkItemTypes, IExtranetSprintItem, ReleaseTimeZone } from '@shared/types';

/**
 * Formats a string or number into USD
 * @example formatMoney(1) => $1.00
 * @example formatMoney(0) => $0.00
 * @example formatMoney('1.99') => $1.99
 * @example formatMoney('9999.99') => $9,999.99
 * @example formatMoney(undefined) => $0.00
 * @example formatMoney(null) => $0.00
 * @param value number | string | undefined | null
 * @param digits number | undefined
 * @returns string
 */
export const formatMoney = (value: number | string | undefined | null, digits = 2): string => {
  let amount = 0;

  if (value) {
    if (typeof value === 'string') {
      // strip out any commas or dollar signs so that $9,999.99 passes !isNaN test
      value = value.includes(',') || value.includes('$') ? value.replace(',', '').replace('$', '') : value;
      // make sure the string is a number
      if (!isNaN(value as unknown as number)) {
        amount = Number(value);
      }
    } else if (typeof value === 'number') {
      amount = value;
    }
  }
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};

export const stripMoneyFormat = (value: number | string | undefined | null, digits = 2): number | string | undefined | null => {
  if (value) {
    if (typeof value === 'string') {
      value = value.includes(',') || value.includes('$') ? value.replace(/,/g, "").replace(/\$/g, "") : value;
      // make sure the string is a number
      if (!isNaN(value as unknown as number)) {
        return Number(value);
      }
    }
  }

  return value;
};

// Formats a date string into "MM/DD/YYYY hh:mm A" format (e.g., "08/26/2024 07:00 AM").
export const formatDateTime = (dateString: string) => {
  return moment(dateString).format('MM/DD/YYYY hh:mm A');
};

/**
 * Formats a Date string into a string with the day of the week
 * @example formatDateWithDay(2020-03-31) => 3/24/2020 - Tuesday
 * @param value Date
 * @returns string
 */
export const formatDateWithDay = (value: string, withDay = true) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const day = new Date(value).getUTCDay();
  const dateArray = value.split('-');

  return `${withDay ? days[day] + ' ' : ''}${parseInt(dateArray[1], 10)}/${parseInt(dateArray[2], 10)}/${dateArray[0]}`;
};

/**
 * Formats a Date object into a string the api can parse
 * @example getDateInputFormat(new Date) => 2020-03-24
 * @param value Date
 * @returns string
 */
export const getDateInputFormat = (value: Date): string => {
  return format(new Date(value), 'yyyy-MM-dd');
};

/**
 * Formats a Date object into a string the api can parse
 * @example getDayOfWeek(new Date) => M
 * @param value Date
 * @returns string
 */
export const getDayOfWeek = (value: Date): string => {
  return format(new Date(value), 'EEEEE');
};

/**
 * Formats a Date object into a string the csv module can parse
 * @example shortFileDateTime(new Date) => M
 * @param value Date
 * @returns string
 */
export const shortFileDateTime = (value: Date): string => {
  return format(new Date(value), 'M_d_yyyy_h_mm_a');
};

/**
 * Format date into a short friendly date with time
 * @example 1/6/2020 2:00pm
 * @example 12/12/2020 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatShortFriendlyDateWithTime = (date: Date | number | string): string => {
  const _date: Date | number = typeof date === 'string' ? parseISO(date) : date;
  return format(_date, 'Pp');
};

/**
 * Add either "hr" or "hrs" to a number of hours, like a user story effort
 * @example 0 => 0 hrs
 * @example 0.5 => 0.5 hr
 * @example 1 => 1 hr
 * @example 2 =>  2 hrs
 * @param hours number
 * @returns string
 */
export const formatHours = (hours: number): string => {
  let modHours = `${hours}`;

  if (modHours.includes('.') && modHours.split('.')[1].length > 2) {
    modHours = hours.toFixed(2);
  }
  return `${modHours} ${hours > 0 && hours <= 1 ? 'hr' : 'hrs'}`;
};

/**
 * Format sprint name and year for display and exporting
 * @example (08, 2020) => "Sprint 08.2020"
 * @param sprintIteration string | undefined
 * @param sprintYear  string | undefined
 * @param sprintIterationAndYear  string | undefined
 * @returns string
 */
export const formatSprint = (sprintIteration?: string, sprintYear?: string, sprintIterationAndYear?: string): string => {
  let formatted = '';

  if (sprintIteration) {
    if (sprintIteration.match(/(Sprint|Jog) \d{4}\.\d+\.\d+/gm)) {
        return sprintIteration;
    }
    else if (sprintIteration.match(/internal/)) return '';
  }


  if (sprintIterationAndYear) {
    const parts = sprintIterationAndYear.split('.');

    if (parts.length === 2) {
      sprintIteration = parts[1];
      sprintYear = parts[0];

      if (sprintIteration.includes('Sprint')) {
        formatted = `${sprintIteration}`;
      } else {
        formatted = `Sprint ${sprintYear}.${sprintIteration}`;
      }
    }
  } else if (sprintIteration && sprintYear) {
    if (sprintIteration.includes('Sprint')) {
      formatted = `${sprintIteration}`;
    } else {
      formatted = `Sprint ${sprintYear}.${sprintIteration}`;
    }
  }

  return formatted;
};

/**
 * Format work item type for display
 * @example ('Story') => 'Stories'
 * @example ('Story', true) => 'stories'
 * @param workItemType WorkItemTypes
 * @param lower boolean | undefined
 * @returns string
 */
export const formatWorkItemType = (workItemType: WorkItemTypes, lower?: boolean): string => {
  return `${
    workItemType === 'Story' && lower
      ? 'stories'
      : workItemType === 'Story'
      ? 'Stories'
      : lower
      ? `${workItemType.toLowerCase()}s`
      : `${workItemType}s`
  }`;
};

export const renderSprintLabel = (sprint: IExtranetSprintItem) => {
  if (sprint.name.indexOf(' - ') > -1) {
    return sprint.name;
  } else {
    return `${sprint.name} (${format(new Date(sprint.startDate as string), 'MMM d')} - ${format(new Date(sprint.endDate as string), 'MMM d')})`;
  }
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const unCamelCase = (str: string) => {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};
export enum TimesheetNameColor {
  red = '#EC2323',
  blue = '#4596CC'
}

export const formatDate = (date: string) => {
  return `${date.trim()} 00:00:00`;
};

/**
 *
 * @param path string the key of the object you are trying to resolve the value of
 * @param obj the object that gets passed in to check against
 * @returns the value of the object at the given path, i.e. path='test.test.value' obj={{ test: { test: { value: 'TEST' } }  }}
 */
export const resolveObjectField = (path: string, obj: any) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

export const getOrdinalNumber = (n: number) => {
  let s = ["th", "st", "nd", "rd"];
  let v = n % 100;
  return n + (s[(v-20)%10] || s[v] || s[0]);
}

// Software Release Helpers start here //

// Maps U.S. time zone abbreviations to IANA identifiers.

export const timeZoneMapping = {
  EST: 'America/New_York',
  CST: 'America/Chicago',
  MST: 'America/Denver',
  PST: 'America/Los_Angeles'
};

// Returns an array of timezone objects based on the provided ReleaseTimeZone
export const getTimezones = (ReleaseTimeZone: any) => [
  { id: 1, value: ReleaseTimeZone.EST, label: 'EST (GMT -4:00)' },
  { id: 2, value: ReleaseTimeZone.CST, label: 'CST (GMT -5:00)' },
  { id: 3, value: ReleaseTimeZone.MST, label: 'MST (GMT -6:00)' },
  { id: 4, value: ReleaseTimeZone.PST, label: 'PST (GMT -7:00)' }
];

export const calculateReleaseWithTime = (releaseDate: Date | null, time: string): moment.Moment | null => {
  if (!releaseDate || !time) return null;
  let [hours, minutes] = time.split(':').map(Number);
  // Convert releaseDate to moment object
  const targetReleaseDateAndTime = releaseDate.setHours(hours, minutes, 0, 0);
  let startMoment = moment(targetReleaseDateAndTime);
  // Return the final end date
  return startMoment;
};

// calculate release lengths in 30 min increments up to 5 hours //
export const releaseLengths = Array.from({ length: 10 }, (_, i) => {
  const totalMinutes = (i + 1) * 30;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return {
    value: `${hours}:${minutes.toString().padStart(2, '0')}`,
    label: hours > 0 ? `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}` : `${minutes}m`
  };
});

export const getReleaseTimeZoneName = (TimeZone: string) => {
  if (ReleaseTimeZone.EST === TimeZone) {
    return 'America/New_York';
  } else if (ReleaseTimeZone.CST === TimeZone) {
    return 'America/Chicago';
  } else if (ReleaseTimeZone.MST === TimeZone) {
    return 'America/Denver';
  } else if (ReleaseTimeZone.PST === TimeZone) {
    return 'America/Los_Angeles';
  }
  return '';
};