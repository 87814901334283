import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, ExpandMore, Print, Remove } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { Loader } from '@shared/components/loader';
import { formatHours } from '@shared/helpers';
import { IUserStory, IUserStoryDetail } from '@shared/types';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { BLOCKED_COLOR } from '@shared/constants';
import { UserStoryComments } from './UserStoryComments';
interface IUserStoryDetailProps {
  currentStoryId: number | null;
  isLoadingStoryDetail: boolean;
  /** Undefined until loading is done. */
  selectedStory?: IUserStoryDetail;
  isUserStoryNotAuthorized?: boolean;
  isModalView?: boolean;
  printUserStory?: boolean;
  userStory?: IUserStory;
  isDeepLink?: boolean;
}

export const UserStoryDetail: FC<IUserStoryDetailProps> = ({
  isUserStoryNotAuthorized,
  currentStoryId,
  isLoadingStoryDetail,
  selectedStory,
  isModalView,
  printUserStory,
  userStory,
  isDeepLink
}) => {
  const classes = userStoryDetailStyles();
  const printClasses = printViewStyles();

  const isMobile = useMedia('(max-width: 960px)');
  const history = useHistory();

  const [isShowingMore, showMore] = useState<boolean>(false);
  const [isShowingMoreButton, setShowMoreButton] = useState<boolean>(false);

  const acContainer = useRef<HTMLDivElement | null>(null);
  const printBtnRef = useRef<HTMLButtonElement | null>(null);

  const style = isShowingMore && !!acContainer.current ? { maxHeight: acContainer.current.scrollHeight * 2 } : undefined;

  if (acContainer.current !== null && acContainer.current.scrollHeight > 300 && !isShowingMoreButton) setShowMoreButton(true);

  // Clicking print button on a Grid view UserStoryDetail opens the modal and
  // auto-clicks the print button, printing or cancelling the print auto-closes
  // the modal
  useEffect(() => {
    if (printUserStory && selectedStory && isModalView && !isLoadingStoryDetail && !!printBtnRef.current) {
      // Wait a tick for the browser to load the fonts for printing
      Promise.resolve().then(() => {
        // Initiate the browser print (window.print() freezes the browser, this is a work-around)
        printBtnRef.current?.click();
        // Unset the `workitems` URL param to hide the modal
        history.goBack();
      });
    }
  }, [selectedStory, printUserStory, isModalView, isLoadingStoryDetail, history]);

  return (
    <div className={classes.detail} id='print-user-story-content'>
      {isLoadingStoryDetail && (!selectedStory || (selectedStory && selectedStory.id === currentStoryId)) && (
        <Loader type='overlay' position='centered' />
      )}
      {isUserStoryNotAuthorized && (
        <Alert severity='error' className={classes.fullWidth}>
          You do not have access to this Client. Please contact MercuryWorks Support if you feel this is in error.
        </Alert>
      )}
      {selectedStory && !isUserStoryNotAuthorized && (
        <Grid container={true} alignContent='center' spacing={2}>
          {/** AC Expansion Panel */}
          <Grid item={true} xs={12} md={8}>
            <ExpansionPanel classes={{ root: classes.panelACRoot }} defaultExpanded={true}>
              <ExpansionPanelSummary
                classes={{ root: classes.panelSummaryACStoryRoot }}
                expandIcon={<ExpandMore />}
                aria-controls={`ac-${selectedStory.id}`}
                id={`ac-${selectedStory.id}`}
              >
                <Typography className={classes.bold}>Acceptance Criteria</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.panelDetailsACRoot }}>
                {selectedStory.acceptanceCriteria ? (
                  <div className={classes.fullWidth}>
                    <div
                      style={isShowingMoreButton && !style ? { maxHeight: '300px', overflow: 'hidden' } : style}
                      ref={acContainer}
                      className={classes.acceptanceCriteria}
                      dangerouslySetInnerHTML={{ __html: selectedStory.acceptanceCriteria || '' }}
                    />
                    {isShowingMoreButton && (
                      <Button
                        size='small'
                        color='primary'
                        disableRipple
                        className={printClasses.hidden}
                        startIcon={isShowingMore ? <Remove /> : <Add />}
                        onClick={() => showMore(!isShowingMore)}
                      >
                        Show {isShowingMore ? 'Less' : 'More'}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Typography color='textSecondary' className={classes.margin}>
                    No Acceptance Criteria
                  </Typography>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/** Comments */}
            {!isMobile && selectedStory.id === currentStoryId && <UserStoryComments currentStoryId={currentStoryId} selectedStory={selectedStory} />}
          </Grid>

          {/** Right-side column */}
          <Grid item xs={12} md={4}>
            <Typography className={classes.projectEmailLabel}>Product Manager Email</Typography>
            <Typography className={classes.value}>
              {selectedStory.productManagerEmail ? (
                <a
                  className={classes.projectEmailLink}
                  rel='noopener noreferrer'
                  href={`mailto:${selectedStory.productManagerEmail}`}
                  target='_blank'
                >
                  {selectedStory.productManagerEmail}
                </a>
              ) : (
                'N/A'
              )}
            </Typography>
            {userStory?.state !== 'Done' && (
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.effortLabel}>Total Effort</Typography>
                  <Typography className={classes.value}>{selectedStory.effort ? formatHours(selectedStory.effort) : 'N/A'}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.effortLabel}>Remaining Effort</Typography>
                  <Typography className={classes.value}>{selectedStory.remainingWork ? formatHours(selectedStory.remainingWork) : 'N/A'}</Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {userStory && (
                <>
                  <Typography className={classes.effortLabel}>Project Name</Typography>
                  <Typography className={userStory?.state === 'Blocked' ? classes.projectTitleBlocked : classes.projectTitle}>
                    {userStory?.clientProjectName || ''}
                  </Typography>
                </>
              )}
            </Grid>
            {/** Comments, mobile view */}
            {isMobile && selectedStory.id === currentStoryId && <UserStoryComments currentStoryId={currentStoryId} selectedStory={selectedStory} />}
          </Grid>
        </Grid>
      )}

      {/** Print button */}
      {selectedStory && (
        <div className={clsx(classes.printBtn, printClasses.hidden)}>
          <Button
            disableRipple
            className={printClasses.hidden}
            ref={printBtnRef}
            color='primary'
            startIcon={<Print />}
            onClick={() => {
              if (isModalView) {
                ReactGA.event({
                  category: 'User Stories',
                  action: 'Clicked Print Story in Modal view',
                  label: `Printing Story ${selectedStory.id}`
                });
                // This avoid unnecessary blank pages when printing
                document.getElementById('root')?.classList.add(printClasses.hidden);
                window.print();
                // Code execution is frozen while the browser print view is
                // open, closing it immmediately resumes execution so this class
                // is removed ASAP afterwards
                document.getElementById('root')?.classList.remove(printClasses.hidden);
              } else {
                // Triggers modal to open, rendering a UserStoryDetail and
                // programatically autoclicks this button with isModalView=true
                history.push(`?workitem=${selectedStory.id}`);
              }
            }}
          >
            Print
          </Button>
        </div>
      )}
    </div>
  );
};

export const printViewStyles = makeStyles(() => ({
  /** Hidden if not in print view. */
  visible: {
    display: 'none',
    '@media print': {
      display: 'initial'
    }
  },
  hidden: {
    '@media print': {
      display: 'none'
    }
  }
}));

const userStoryDetailStyles = makeStyles((theme: Theme) => {
  return {
    projectTitle: {
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(15)
      }
    },
    projectTitleBlocked: {
      color: BLOCKED_COLOR,
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(15)
      }
    },
    printBtn: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      padding: theme.spacing(0, 0.5)
    },
    detail: {
      // needed so that the modal doesn't have vertical scrollbars because of negative margin from the container spacing
      padding: theme.spacing(0.5, 0),

      '@media print': {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 9999,
        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        fontSize: '12pt !important',

        '& .MuiExpansionPanelSummary-expandIcon': {
          display: 'none'
        }
      }
    },
    panelACRoot: {
      margin: theme.spacing(1, 0),
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderTop: `0 !important`,
      boxShadow: 'none',
      '&:before': {
        display: 'none'
      }
    },
    panelSummaryACStoryRoot: {
      margin: 0,
      padding: 0,
      borderBottom: `1px solid #c6c6c6`,
      minHeight: `0 !important`,
      '&& .MuiExpansionPanelSummary-content.Mui-expanded': {
        margin: 0
      },
      '&& .MuiIconButton-root': {
        padding: '3px'
      }
    },
    panelDetailsACRoot: {
      padding: `8px 0 0 !important`
    },
    acceptanceCriteria: {
      '@media print': {
        // Overwrite inline styles
        maxHeight: 'initial !important',
        overflow: 'visible !important'
      },
      wordBreak: 'break-word',
      overflow: 'auto',
      marginBottom: theme.spacing(2),
      '&& ul': {
        padding: `0 0 0 10px !important`,
        [theme.breakpoints.up('md')]: {
          padding: `0 0 0 30px !important`
        }
      }
    },
    margin: {
      margin: theme.spacing(1, 0)
    },
    value: {
      marginTop: theme.spacing(0.25),
      marginBottom: theme.spacing(2)
    },
    bold: {
      fontWeight: 700
    },
    projectEmailLabel: {
      fontWeight: 'bold',
      paddingBottom: '5px',
      borderBottom: `1px solid #c6c6c6`
    },
    projectEmailLink: {
      wordBreak: 'break-all'
    },
    effortLabel: {
      fontWeight: 'bold',
      paddingBottom: '5px',
      borderBottom: `1px solid #c6c6c6`
    },
    fullWidth: {
      width: '100%'
    }
  };
});
