import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isUndefined, isNull, capitalize } from 'lodash';

import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  GridSize,
  MenuItem,
  MuiThemeProvider,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, Theme, createMuiTheme } from '@material-ui/core/styles';

import { PieDatum, ResponsivePie } from '@nivo/pie';
import { ClientContacts } from '../components/DashboardClientContacts';
import { getProject, getClientProjects, getExtranetSprintList, getWorkItemCounts, getProgressReports } from '@shared/fetch';
import {
  barChartColors,
  budgetChartColors,
  buildBudgetGraphData,
  buildStoryCompletionChart,
  calculateStoryCompletionPercent,
  formatMoney
} from '@shared/helpers';
import { IAppState, IClientProject, IProject, IBasicObject, DESC_SORT, IProgressReport, IUserProps } from '@shared/types';
import { Empty } from '@shared/components/empty';
import { ProgramIncrementCalendar } from '@shared/components/widgets';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TodayIcon from '@material-ui/icons/Today';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { SharedFiles } from './SharedFiles';
import { DashboardCard } from '../components/DashboardCard';
import { useMedia } from 'react-use';
import { ResponsiveBar } from '@nivo/bar';
import { setCurrentReport } from '@shared/redux/actions';
import { format } from 'date-fns';
import { LoadingReportGridCard } from '../components/LoadingReportGridCard';
import { ReportGridCard } from '../components/ReportGridCard';
import { Page } from '@shared/components/layout';
import { UpcomingReleases } from '@shared/components/widgets/UpcomingReleases';
import { OpenRequests } from '@shared/components/widgets/OpenRequestsWidget';

export const ClientDashboard: FC = () => {
  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));
  const { userAccess } = useSelector((state: IAppState) => state.user);
  const history = useHistory();
  const classes = ClientDashboardStyles();
  // States used to create a custom legend
  const PIE_CHART_STATES = ['New', 'Approved', 'In Progress', 'Done', 'Blocked'];
  const PIE_CHART_COLORS = ['#ccc', '#ffa011', '#0d5cab', '#72c267', '#e22f2f'];

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [clientProjects, setClientProjects] = useState<IClientProject[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProject | null | undefined>();
  const [budgetGraphData, setBudgetGraphData] = useState<IBasicObject[] | undefined>();
  const [budgetGraphMax, setBudgetGraphMax] = useState<number | undefined>();
  const [storyCompletionData, setStoryCompletionData] = useState<PieDatum[] | undefined>();
  const [storyCompletionPercent, setStoryCompletionPercent] = useState<string | undefined>();
  const [storyItemCount, setStoryItemCount] = useState<number[]>([]);
  const [totalStoryCount, setTotalStoryCount] = useState<number>(0);
  const [newItemCount, setNewItemCount] = useState<number>(0);
  const [approvedItemCount, setApprovedItemCount] = useState<number>(0);
  const [inProgressItemCount, setInProgressItemCount] = useState<number>(0);
  const [doneItemCount, setDoneItemCount] = useState<number>(0);
  const [lineTickWidth, setLineTickWidth] = useState<number>(0);
  const [partialTickWidth, setPartialTickWidth] = useState<number>(0);
  const [currentSprintName, setCurrentSprintName] = useState<string>('');
  const [progressReports, setProgressReports] = useState<IProgressReport[]>([]);
  const [isProgressLoading, setProgressLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const dashboardChartStyles = ClientDashboardChartsStyles();
  const isMobile = useMedia('(max-width: 768px)');
  const isTablet = useMedia('(max-width: 960px)');
  const gridBreakpoint: GridSize = isMobile ? 12 : isTablet ? 6 : 4;
  const loadingProgressReportSize = [1, 2, 3];

  const getColorForChart = (bar: { id: string | number }) => budgetChartColors[bar.id];

  const fetchProgressReports = async () => {
    if (selectedClient) {
      setProgressReports([]);
      if (!isProgressLoading) {
        setProgressLoading(true);
      }
      try {
        const res = await getProgressReports(selectedClient.clientId, {
          page: 1,
          perPage: 10,
          sortBy: 'SprintDate',
          sortDirection: DESC_SORT
        });

        // We only want the 3 most recent items and since it is delivered in order
        // of publish date, simply take the first 3 from the response
        setProgressReports(res.records.slice(0, 3));
      } catch (error) {
        console.error(error);
      } finally {
        setProgressLoading(false);
      }
    }
  };

  // effects
  useEffect(() => {
    fetchProgressReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, selectedProject]);

  const getSelectedProject = async (projectId: string | number) => {
    setIsProjectLoading(true);
    try {
      // Set the current sprint name when and if the project changes
      const sprintListResponse = await getExtranetSprintList();
      if (sprintListResponse && sprintListResponse.currentSprint && sprintListResponse.currentSprint.sprintName) {
        setCurrentSprintName(sprintListResponse.currentSprint.sprintName);
      }

      // Get the current project
      const project = await getProject(projectId, selectedClient?.clientId ?? 0, { withBudget: userAccess.ClientFinancialsData });
      setSelectedProject(project);

      // Get the current sprint work item counts
      const workItemCounts = await getWorkItemCounts(
        selectedClient?.clientId ?? 0,
        ['Bug', 'Story'],
        sprintListResponse?.currentSprint?.projectIterationUuid || undefined
      );

      const projectCount = workItemCounts?.counts ?? [];

      // Get the total counts for each project status
      if (projectCount.length > 0) {
        const newItems = projectCount.filter(story => story.status === 'New');
        let newCount = 0;
        newItems.forEach(item => (newCount += item.count));
        setNewItemCount(newCount);

        const approvedItems = projectCount.filter(story => story.status === 'Approved');
        let approveCount = 0;
        approvedItems.forEach(item => (approveCount += item.count));
        setApprovedItemCount(approveCount);

        // @ts-ignore
        const inProgressItems = projectCount.filter(story => story.status === 'InProgress');
        let inProgressCount = 0;
        inProgressItems.forEach(item => (inProgressCount += item.count));
        setInProgressItemCount(inProgressCount);

        const doneItems = projectCount.filter(story => story.status === 'Done');
        let doneCount = 0;
        doneItems.forEach(item => (doneCount += item.count));
        setDoneItemCount(doneCount);

        setTotalStoryCount(newCount + approveCount + inProgressCount + doneCount);
      }
    } catch (error) {
      console.error(error);
      setApiError(true);
    } finally {
      setIsProjectLoading(false);
    }
  };

  useEffect(() => {
    const getProjects = async function () {
      // If there's a project currently selected reset it
      if (!isEmpty(selectedProject)) {
        setSelectedProject(null);
      }

      try {
        setLoading(true);
        // Only get the approved projects
        const projects = await getClientProjects({ ClientId: selectedClient?.clientId, ProjectStatus: 'Approved', IncludeAllProjects: true });
        const filteredProjects = projects.filter(
          project =>
            project.name === 'All Projects' ||
            // Filter out projects that have a budget, have a budget greater than 0 and aren't fixed price
            (!isNull(project.budgetedAmount) &&
              project.budgetedAmount &&
              project.budgetedAmount > 0 &&
              !project.fixedPrice &&
              // Filter out projects that are not supposed to be shown in the client portal
              project.shouldShowInClientPortal)
        );
        setClientProjects(filteredProjects);
      } catch (error) {
        console.error(error);
        setApiError(true);
      } finally {
        setLoading(false);
      }
    };

    if (selectedClient) {
      getProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    if (!isEmpty(clientProjects)) {
      const projectId = clientProjects[0]?.clientProjectId;
      getSelectedProject(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProjects]);

  // Build the graph data when the project is changed
  useEffect(() => {
    if (!isEmpty(selectedProject) && !isUndefined(selectedProject) && !isNull(selectedProject)) {
      const { budgetSpentAmount, budgetedAmount, budgetInvoicedAmount, userStoryCounts } = selectedProject;
      const budgetGraphMax = Math.max(budgetedAmount || 0, (budgetSpentAmount || 0) + (budgetInvoicedAmount || 0));
      // Set the budget graph max + an extra 15% of the max value for some padding
      setBudgetGraphMax(budgetGraphMax + budgetGraphMax * 0.15);
      setBudgetGraphData(buildBudgetGraphData(budgetSpentAmount, budgetedAmount, budgetInvoicedAmount));
      setStoryCompletionData(buildStoryCompletionChart(userStoryCounts?.counts));
      setStoryCompletionPercent(calculateStoryCompletionPercent(selectedProject.workCompletionPercentage));
    }
  }, [selectedProject]);

  useEffect(() => {
    const getLineChartValues = () => {
      const arrayFromLength = Array.from(Array(totalStoryCount).keys());

      // We don't want too many numbers on the line chart, so this divider regulates the maximum number
      // of tick marks that can be displayed on screen at once
      let divider = 4;
      if (totalStoryCount > 50 && totalStoryCount < 100) {
        divider = 8;
      } else if (totalStoryCount > 100) {
        divider = 12;
      }

      // Take the total number of ticks across the line graph and figure out the
      // width each tick segment should have
      const initialTickWidth = 100 / (totalStoryCount / divider);
      const remainderTickWidth = 100 % initialTickWidth;
      // Set the width between each tick mark
      setLineTickWidth(initialTickWidth);
      // This will always be the last tick, which can have a varying size
      setPartialTickWidth(remainderTickWidth);
      // Make an array of the total number of stories so we can iterate over it
      // to create the line graph
      setStoryItemCount(arrayFromLength.filter(number => number % divider === 0));
    };

    getLineChartValues();
  }, [totalStoryCount]);

  const getLineTickWidth = (item: number) => {
    if (storyItemCount[storyItemCount.length - 1] === item) {
      return Math.floor(partialTickWidth);
    } else {
      return Math.floor(lineTickWidth);
    }
  };

  const user: IUserProps = useSelector((state: IAppState) => state.user);
  const {
    featureFlags,
    userAccess: { ClientFinancialsData }
  } = user;
  const clientReleaseNotes = !!featureFlags.find(flag => flag.name === 'mymwks-client-release-notes')?.hasAccess;
  const clientRequestsDashboardWidget = !!featureFlags.find(flag => flag.name === 'client-requests-dashboard-widget')?.hasAccess;

  return (
    <Page title='Clients Dashboard' hideHeader isCard={false}>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item xs={12} lg={8}>
          <DashboardCard
            setHeight={false}
            isColumn={false}
            title={ClientFinancialsData ? 'Progress vs Spend' : 'Progress'}
            actions={
              <Grid container justify='space-around'>
                <Grid item xs={12}>
                  {!isLoading && selectedClient && isEmpty(clientProjects) && (
                    <Box fontWeight='bold' component='span'>
                      There are no active projects for {selectedClient.name}
                    </Box>
                  )}
                  {!isLoading && (!isEmpty(clientProjects) || isProjectLoading) && selectedProject && (
                    <div className={isMobile ? dashboardChartStyles.mobileSelector : dashboardChartStyles.selector}>
                      <FormControl className={dashboardChartStyles.projectSelect}>
                        <MuiThemeProvider theme={SelectTheme}>
                          <TextField
                            select
                            variant='outlined'
                            size='small'
                            id='client-project-select'
                            label='Select Project'
                            value={selectedProject ? selectedProject.clientProjectId : 0}
                            onChange={(event: ChangeEvent<{ value: unknown }>) => getSelectedProject(event.target.value as number)}
                            disabled={isLoading || isProjectLoading}
                            InputProps={{
                              endAdornment: isLoading ? (
                                <div className={dashboardChartStyles.inputLoader}>
                                  <CircularProgress size={20} />
                                </div>
                              ) : null
                            }}
                          >
                            {clientProjects &&
                              clientProjects.map(project => (
                                <MenuItem
                                  classes={{ root: dashboardChartStyles.menuItem }}
                                  key={project.clientProjectId}
                                  value={project.clientProjectId ?? 0}
                                >
                                  {project.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        </MuiThemeProvider>
                      </FormControl>
                    </div>
                  )}
                </Grid>
              </Grid>
            }
          >
            <Grid item xs={12} md={6}>
              {isProjectLoading || isLoading ? (
                <Skeleton height={400} width='80%' />
              ) : (
                <>
                  <Typography variant='h3' component='h3' align='center' className={dashboardChartStyles.graphTitle}>
                    Work Completion
                  </Typography>
                  {storyCompletionData && storyCompletionData.some(data => data.value > 0) ? (
                    <Typography align='center' variant='h4' component='h4' className={dashboardChartStyles.info}>
                      {storyCompletionPercent} Complete
                    </Typography>
                  ) : (
                    <Typography align='center' variant='h4' component='h4' className={dashboardChartStyles.info}>
                      No Completion Data to Show
                    </Typography>
                  )}
                  {storyCompletionData && storyCompletionData.some(data => data.value > 0) && (
                    <>
                      <div className={dashboardChartStyles.chartContainer}>
                        <ResponsivePie
                          data={storyCompletionData}
                          innerRadius={0.5}
                          enableRadialLabels={false}
                          margin={{ top: 10, left: 20, right: 20, bottom: 30 }}
                          colors={PIE_CHART_COLORS}
                          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                          slicesLabelsTextColor={{ from: 'color', modifiers: [] }}
                          sliceLabel={d => (d.value > 0 ? `${Math.round(d.value * 100)}%` : '')}
                          tooltip={d =>
                            d.value > 0 ? (
                              <>
                                {d.label}: {Math.round(d.value * 100)}%
                              </>
                            ) : null
                          }
                        />
                      </div>
                      <Grid container alignItems='center' justify='center' spacing={1} className={dashboardChartStyles.barchartlegend}>
                        {PIE_CHART_COLORS.map((color, index) => (
                          <Grid item key={color}>
                            <Grid container alignItems='center'>
                              <Grid item>
                                <Box height='20px' width='20px' mr='.5rem' style={{ backgroundColor: color }} />
                              </Grid>
                              <Grid item>
                                <span>{PIE_CHART_STATES[index]}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            {ClientFinancialsData && (
              <Grid item xs={12} md={6}>
                {isProjectLoading || isLoading ? (
                  <Skeleton height={400} width='80%' />
                ) : (
                  selectedProject && (
                    <div style={{ minHeight: 400 }}>
                      <div>
                        <Typography variant='h3' component='h3' align='center' className={dashboardChartStyles.graphTitle}>
                          Budget Consumption
                        </Typography>
                        {selectedProject.budgetedAmount > 0 ? (
                          <Typography align='center' variant='h4' component='h4' className={dashboardChartStyles.info}>
                            <span>
                              {Math.round(
                                ((selectedProject.budgetSpentAmount + selectedProject.budgetInvoicedAmount) / selectedProject.budgetedAmount) * 100
                              )}
                              % Spent
                            </span>
                          </Typography>
                        ) : (
                          <Typography align='center' variant='h4' component='h4' className={dashboardChartStyles.info}>
                            No budget for selected project.
                          </Typography>
                        )}
                      </div>
                      {budgetGraphData && selectedProject.budgetedAmount > 0 && (
                        <div className={dashboardChartStyles.chartContainer}>
                          <ResponsiveBar
                            margin={{ top: 50, left: 80, bottom: 30, right: 0 }}
                            data={budgetGraphData}
                            maxValue={budgetGraphMax}
                            keys={['budget', 'invoiced', 'spent']}
                            indexBy='id'
                            colors={getColorForChart}
                            enableLabel={false}
                            axisBottom={{ legend: '' }}
                            axisLeft={{ legend: '', legendOffset: -60, legendPosition: 'middle', format: d => `${formatMoney(d, 0)}` }}
                            tooltip={d =>
                              d.value > 0 ? (
                                <>
                                  {capitalize(d.id as string)}: {formatMoney(d.value, 0)}
                                </>
                              ) : null
                            }
                          />
                          <Grid container alignItems='center' justify='flex-end' spacing={1} className={dashboardChartStyles.barchartlegend}>
                            {barChartColors.map(
                              (color, index) =>
                                index !== 0 && (
                                  <Fragment key={`${index} ${color}`}>
                                    <div className={dashboardChartStyles.legendItem}>
                                      <Box height='20px' width='20px' mr='.5rem' style={{ backgroundColor: color }} />
                                      <span>{index === 1 ? 'Invoiced' : 'Spent'}</span>
                                    </div>
                                  </Fragment>
                                )
                            )}
                          </Grid>
                        </div>
                      )}
                    </div>
                  )
                )}
              </Grid>
            )}
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProgramIncrementCalendar stackVertically />
        </Grid>
        <Grid item xs={12} lg={clientReleaseNotes ? 8 : 12}>
          {/* PROGRESS REPORTS */}
          {selectedClient?.usesProgressReports && (
            <DashboardCard
              title={'Progress Reports'}
              actions={
                <Button
                  color='primary'
                  variant='contained'
                  disabled={isLoading || isProgressLoading}
                  onClick={() => history.push('/clients/progress-reports')}
                >
                  View All
                </Button>
              }
            >
              {/* PROGRESS REPORTS GRID */}
              {!isProgressLoading && (
                <CardContent className={dashboardChartStyles.progressReportGrid}>
                  <Grid container spacing={2}>
                    {progressReports.map((report: IProgressReport) => (
                      <ReportGridCard
                        key={report.clientPortfolioReportId}
                        isMobile={isMobile}
                        gridBreakpoint={gridBreakpoint}
                        isWidget
                        // reset the current report in state, we will query the correct based on the id from the ProgressReport page
                        reactRouterLinkOnClick={() => dispatch(setCurrentReport(null))}
                        report={{
                          ...report,
                          title: report.sprintName,
                          linkTo: `/clients/progress-reports/${report.clientPortfolioReportId}`
                        }}
                      >
                        {/* SPRINT CREATION DATE/TIME */}
                        {report.published ? (
                          <Typography className={dashboardChartStyles.font}>
                            {format(new Date(report.dateUpdated || report.dateCreated), 'Pp')}
                          </Typography>
                        ) : (
                          <Typography color='error'>Draft: Not Visible to Client</Typography>
                        )}
                      </ReportGridCard>
                    ))}
                  </Grid>
                </CardContent>
              )}
              {/* LOADING STATE */}
              {isProgressLoading && (
                <CardContent className={dashboardChartStyles.progressReportGrid}>
                  <Grid container spacing={2}>
                    {loadingProgressReportSize.map(i => (
                      <LoadingReportGridCard key={i} isMobile={isMobile} gridBreakpoint={gridBreakpoint} />
                    ))}
                  </Grid>
                </CardContent>
              )}
            </DashboardCard>
          )}
        </Grid>
        {clientReleaseNotes && (
          <Grid item xs={12} lg={4}>
            <UpcomingReleases />
          </Grid>
        )}

        <Grid item xs={12} lg={8}>
          {/* USER STORIES */}
          <DashboardCard
            title={`In Progress${currentSprintName ? ` - ${currentSprintName}` : ''}`}
            actions={
              selectedClient?.clientPlanningMode === 'Scrum' && (
                <Button color='primary' variant='contained' disabled={isLoading || isProjectLoading} onClick={() => history.push('/clients/board')}>
                  View All
                </Button>
              )
            }
          >
            {!isLoading && !isProjectLoading && selectedClient?.clientPlanningMode === 'Scrum' ? (
              <Grid item xs={12}>
                {!totalStoryCount ? (
                  <Empty messages={['No work planned for the current sprint. Click VIEW ALL to see more details.']} />
                ) : (
                  <div>
                    {/* USER STORY CHART */}
                    <div className={classes.storyChartContainer}>
                      {/* NEW ITEMS */}
                      {newItemCount > 0 && (
                        <div
                          className={`${classes.storyChartSegment} ${classes.newSegment}`}
                          style={{ width: `${Math.floor((newItemCount / totalStoryCount) * 100)}%` }}
                        >
                          <FiberNewIcon />
                          {newItemCount}
                        </div>
                      )}
                      {/* APPROVED ITEMS */}
                      {approvedItemCount > 0 && (
                        <div
                          className={`${classes.storyChartSegment} ${classes.approvedSegment}`}
                          style={{ width: `${Math.floor((approvedItemCount / totalStoryCount) * 100)}%` }}
                        >
                          <ThumbUpIcon />
                          {approvedItemCount}
                        </div>
                      )}
                      {/* IN PROGRESS ITEMS */}
                      {inProgressItemCount > 0 && (
                        <div
                          className={`${classes.storyChartSegment} ${classes.inProgressSegment}`}
                          style={{ width: `${Math.floor((inProgressItemCount / totalStoryCount) * 100)}%` }}
                        >
                          <TodayIcon />
                          {inProgressItemCount}
                        </div>
                      )}
                      {/* DONE ITEMS */}
                      {doneItemCount > 0 && (
                        <div
                          className={`${classes.storyChartSegment} ${classes.doneSegment}`}
                          style={{ width: `${Math.floor((doneItemCount / totalStoryCount) * 100)}%` }}
                        >
                          <CheckCircleOutlineIcon />
                          {doneItemCount}
                        </div>
                      )}
                    </div>
                    {/* LINE GRAPH */}
                    <div className={classes.lineChart}>
                      {storyItemCount.map((item: number) => {
                        return (
                          <div className={classes.countSegment} style={{ width: `${getLineTickWidth(item)}%` }}>
                            <div className={classes.tick} />
                            <Typography variant={'h5'}>{item}</Typography>
                          </div>
                        );
                      })}
                    </div>
                    {/* LEGEND */}
                    <Grid container alignItems='center' justify='center' spacing={1} className={classes.legendContainer}>
                      {PIE_CHART_COLORS.map((color, index) => (
                        <Grid item key={color}>
                          <Grid container alignItems='center'>
                            <Grid item>
                              <Box height='20px' width='20px' mr='.5rem' style={{ backgroundColor: color }} />
                            </Grid>
                            <Grid item>
                              <span>{PIE_CHART_STATES[index]}</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
              </Grid>
            ) : (
              <Skeleton height={200} width='98%' />
            )}
          </DashboardCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ClientContacts clientId={selectedClient?.clientId} loading={isLoading} />
        </Grid>
        <Grid item xs={12} lg={clientRequestsDashboardWidget ? 8 : 12}>
          <SharedFiles isWidget />
        </Grid>
        {clientRequestsDashboardWidget && (
          <Grid item xs={12} lg={4}>
            <OpenRequests />
          </Grid>
        )}
      </Grid>
      {apiError && (
        <Empty showIcon messages={['Error']}>
          Problem loading, refresh page to try again!
        </Empty>
      )}
    </Page>
  );
};

// Override the active/focus state for select
// so that we don't have a grey active/focus background
// when a user selects a project. The loading endornment
// has a white background and looks bad if select background
// isn't also white.
const SelectTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        fontFamily: 'Poppins-Light, sans-serif',
        '&:focus, &:active': {
          background: '#fff'
        }
      }
    }
  }
});

const ClientDashboardStyles = makeStyles((theme: Theme) => ({
  cardHolder: {
    alignItems: 'stretch'
  },
  headingContainer: {
    marginBottom: theme.spacing(0.5)
  },
  heading: {
    fontFamily: 'Poppins-Light, sans-serif',
    fontSize: 36,
    lineHeight: 1.2
  },
  projectSelect: {
    minWidth: '300px'
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  dashboardContainer: {
    marginBottom: theme.spacing(1)
  },
  fullHeight: {
    height: '100%'
  },
  card: {
    backgroundColor: '#f1f1f1',
    borderRadius: '3px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    padding: theme.spacing(1)
  },
  chartContainer: {
    minHeight: '400px',
    maxHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cutOff: {
    marginTop: '.75rem'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  projectInformation: {
    width: '100%',
    textAlign: 'center',
    padding: '1rem'
  },
  info: {
    color: theme.palette.info.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 'bold'
  },
  itemBorderBottom: {
    borderBottom: '1px solid #ccc'
  },
  greenText: {
    color: theme.palette.secondary.main
  },
  blueText: {
    color: theme.palette.primary.main
  },
  pointsOfContact: {
    border: '1px solid #ccc'
  },
  chartLoader: {
    marginTop: theme.spacing(2)
  },
  inputFocused: {
    backgroundColor: theme.palette.background.paper
  },
  inputLoader: {
    marginRight: theme.spacing(1.5),
    marginTop: 4
  },
  progressReportCard: {
    marginTop: theme.spacing(1)
  },
  progressReportContainer: {
    padding: theme.spacing(1)
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  chartCard: {
    marginBottom: theme.spacing(1)
  },
  currentUserStoryContainer: {
    marginBottom: theme.spacing(1)
  },
  storyChartContainer: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.body1.fontSize
  },
  storyChartSegment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(4)
  },
  newSegment: {
    backgroundColor: '#ccc'
  },
  approvedSegment: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white
    }
  },
  inProgressSegment: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white
    }
  },
  doneSegment: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white
    }
  },
  lineChart: {
    display: 'flex',
    width: '100%',
    borderTop: `1px solid ${theme.palette.common.black}`
  },
  countSegment: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  tick: {
    backgroundColor: theme.palette.common.black,
    width: '1px',
    height: '10px'
  },
  legendContainer: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize
  },
  viewAllButton: {
    marginTop: theme.spacing(1)
  }
}));

const ClientDashboardChartsStyles = makeStyles((theme: Theme) => ({
  chartCard: {
    marginBottom: theme.spacing(1)
  },
  projectSelect: {
    minWidth: '300px'
  },
  mobileSelector: {
    display: 'flex',
    flexDirection: 'column'
  },
  selector: {
    display: 'flex',
    alignItems: 'baseline'
  },
  mobileCutoff: {
    marginTop: theme.spacing(2)
  },
  cutOff: {
    marginLeft: '1rem'
  },
  fullHeight: {
    height: '100%'
  },
  chartContainer: {
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  info: {
    color: theme.palette.info.main
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  progressReportCard: {
    marginTop: theme.spacing(1)
  },
  progressReportContainer: {
    padding: theme.spacing(1)
  },
  titleIcon: {
    color: theme.palette.primary.main,
    fontSize: '2rem'
  },
  titleText: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  greenText: {
    color: theme.palette.secondary.main
  },
  inputLoader: {
    marginRight: theme.spacing(1.5),
    marginTop: 4
  },

  byMonthButton: {
    float: 'right'
  },
  progressReportGrid: {
    width: '100%'
  },
  barchartlegend: {
    marginRight: `2rem`,
    marginTop: 0,
    fontSize: theme.typography.body1.fontSize,
    '@media (min-width: 1100px)': {
      marginRight: 0
    },
    '@media (min-width: 1400px)': {
      marginRight: `5rem`
    }
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  font: {
    color: theme.palette.text.disabled
  },
  divider: {
    padding: '0 16px'
  },
  graphTitle: {
    color: '#5E5E5E'
  },
  menuItem: {
    fontFamily: 'Poppins-Regular, sans-serif'
  }
}));
