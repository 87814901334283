import { IClientInfo, WorkItemTypeLabels, WorkItemStatusLabels, WorkItemStatuses } from './clients';
import { UserRolesValue } from './role';
import { SortOptions } from './sort';

export enum ClientUserRole {
  Client = 0,
  ClientAdmin = 1,
  Financial = 2,
  Collaborator = 3
}

export type ClientRolesResponse = 'Client' | 'ClientAdmin' | 'Financial' | 'Collaborator';

export const ClientUserRoleNames: { [key in ClientRolesResponse]: ClientRolesResponse } = {
  Client: 'Client',
  ClientAdmin: 'ClientAdmin',
  Financial: 'Financial',
  Collaborator: 'Collaborator'
};

export const clientRolesLabelPayloadMap = {
  'Team Member': ClientUserRole.Client,
  'Client Admin': ClientUserRole.ClientAdmin,
  Financial: ClientUserRole.Financial,
  Collaborator: ClientUserRole.Collaborator
};

export const clientRolesToLabelMap = {
  [ClientUserRoleNames.Client]: 'Team Member',
  [ClientUserRoleNames.ClientAdmin]: 'Client Admin',
  [ClientUserRoleNames.Financial]: 'Financial',
  [ClientUserRoleNames.Collaborator]: 'Collaborator'
};

export interface IExtranetSprintList {
  nextProgressReportSprint: IExtranetSprintItem;
  currentSprint: IExtranetSprintItem;
  sprints: IExtranetSprintItem[];
}
export interface IExtranetSprintItem {
  name: string;
  sprintName?: string;
  startDate?: string;
  endDate?: string;
  projectIterationUuid: number | string | null;
}

export interface IProgramIncrement {
  projectIterationId: number;
  name: string;
  startDate?: string;
  endDate?: string;
  sprints: IExtranetSprintItem[];
}

export interface IExtranetSprintStats {
  budgetSpent: number;
  hours: number;
  userStories: number;
  isFutureSprint: boolean;
  sprintActivities: string;
  mercuryNeeds: string;
}

export interface IUserProject {
  clientProjectId: number;
  name: string;
  isActive?: boolean;
  hashTag?: string;
  budget?: number;
}

export interface IUserStoryEpicRes {
  records: IUserEpic[];
  pageRecordStartIndex: number;
  pageRecordEndIndex: number;
  totalRecordCount: number;
  totalPageCount: number;
  totalEffort?: number;
  totalRemainingWork?: number;
}

export interface IUserEpic {
  childCount: number;
  clientProjectId?: number | null;
  clientProjectName?: string | null;
  remainingWork?: number;
  id: number;
  iterationId?: number;
  sprintName?: string;
  state: WorkItemStatusLabels;
  tags?: string;
  title: string;
  effort?: number;
  totalRemainingWork?: number;
}

export interface IUserStoryFeatureRes {
  records: IUserStoryFeature[];
  pageRecordStartIndex: number;
  pageRecordEndIndex: number;
  totalRecordCount: number;
  totalPageCount: number;
  totalEffort?: number;
  totalRemainingWork?: number;
}

export interface IUserStoryFeature {
  childCount: number;
  clientProjectId?: number | null;
  clientProjectName?: string | null;
  epicId?: number;
  id: number;
  iterationId?: number;
  sprintName?: string;
  state: WorkItemStatusLabels;
  title: string;
  workItemType: WorkItemTypeLabels;
  effort?: number;
  remainingWork?: number;
  totalRemainingWork?: number;
}

export interface IUserStoryRes {
  records: IUserStory[];
  pageRecordStartIndex: number;
  pageRecordEndIndex: number;
  totalRecordCount: number;
  totalPageCount: number;
  totalRemainingWork?: number;
  totalEffort: number;
  remainingEffort?: number;
}

export interface IUserStory {
  acceptanceCriteria?: string;
  clientProjectId?: number | null;
  clientProjectName?: string | null;
  comments?: string; // used when exporting, not returned from the API
  effort?: number;
  featureId?: number;
  id: number;
  iterationId?: number;
  productManagerEmployeeId?: number;
  productManagerName?: string;
  remainingWork?: number;
  sprintContainer?: string;
  sprintName?: string;
  state: WorkItemStatusLabels;
  tags?: string;
  title: string;
  userStoryEdits?: IUserStoryEdit | null;
  workItemType: WorkItemTypeLabels;
}

export interface IUserStoryEdit {
  accepted: boolean;
  requestDate: string;
  requestedTitle: string;
  requestedCriteria: string;
  userStoryId: number;
  userStoryRequestId: number;
  rejected: boolean;
  isPendingReview: boolean;
  clientEmail: string;
  originalTitle: string;
  originalCriteria: string;
  hasBeenReviewed: boolean;
  clientId: number;
}

export interface IUserStoryDetail {
  acceptanceCriteria: string;
  assignedTo: string;
  boardColumn: string;
  changedDate: string;
  clientId: number;
  comments?: string; // used when exporting, not returned from the API
  description: string;
  effort: number;
  featureID: number;
  id: number;
  iterationId: number;
  mode: string;
  priority: number;
  projectAreaId: number;
  projectIteration: {
    projectIterationId: number;
    name: string;
    startDate: string;
    finishDate: string;
  } | null;
  productManagerEmployeeId: number;
  productManagerName: string;
  productManagerEmail?: string;
  remainingWork: number;
  sprintContainer: string;
  sprintName: string | null;
  state: WorkItemStatusLabels;
  tags: string;
  targetDate: string;
  title: string;
  userStoryEdits: IUserStoryEdit;
  workItemType: WorkItemTypeLabels;
}

export interface IExtrantState {
  clients: IClientInfo[];
  isDisabled: boolean;
  selectedClient: IClientInfo | null;
}

export interface IUserStoriesFiltersBase {
  hashTags?: string[];
  page?: number;
  perPage?: number;
  projectIterationUuid?: string | number | null;
  state?: WorkItemStatuses;
}

export interface IUserFeaturesFilters extends IUserStoriesFiltersBase {
  epicId?: number;
}

export interface IUserStoriesFilters extends IUserStoriesFiltersBase {
  featureId?: number;
  includeEdits?: boolean;
}

export interface IUserInteraction {
  userInteractionType: string;
  pageName: string;
}

export interface IExtranetUser {
  clientName?: string;
  clientRoles?: ClientRolesResponse[];
  displayName?: string;
  email: string;
  firstName: string;
  id: string;
  isEnabled: boolean;
  lastLogin?: string;
  lastName: string;
  role?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  userType?: string;
  zendeskUserId?: number;
}

export interface IExtranetUserFilters {
  continuationToken?: string | null;
  displayName?: string;
  clientId?: string | number;
  role?: Omit<UserRolesValue, 'ALL'>;
  clientRole?: ClientRolesResponse;
  isActive?: boolean;
  sortBy?: 'DisplayName' | 'FirstName' | 'LastName' | 'Email';
  sortDirection?: SortOptions;
  page?: number;
  perPage?: number;
}

export interface IExtranetUsersRes {
  records: IExtranetUser[];
  pageRecordStartIndex: number;
  pageRecordEndIndex: number;
  totalRecordCount: number;
  totalPageCount: number;
}

export interface IExtranetUserInvite {
  email: string;
  firstName: string;
  lastName: string;
  clientId: number;
  isActive: boolean;
  sendEmail: boolean;
  emailMessage: string;
  zendeskUserId: number | string;
}

export interface IExtranetUserUpdate {
  email: string;
  firstName: string;
  lastName: string;
  clientId: number;
  isActive: boolean;
  role: string | null;
  sendEmail?: boolean;
  emailMessage?: string;
  zendeskUserId?: number | null;
  clientRoles?: ClientUserRole[];
}

export interface IExtranetUserRequest {
  firstName: string;
  lastName: string;
  accessRequestId: number;
  email: string;
  requestDate: string;
  azureObjectId: string;
}

export interface IExtranetUserRequestRes {
  records: IExtranetUserRequest[];
  pageRecordStartIndex: number;
  pageRecordEndIndex: number;
  totalRecordCount: number;
  totalPageCount: number;
}
export interface IEpicsBacklogFiltersBase {
  projectId?: number;
  showOnlyOpen?: boolean;
}
export interface IFeaturessBacklogFiltersBase {
  projectId?: number;
  epicId?: number;
  showOnlyOpen?: boolean;
}

export interface IUserStoriesBacklogFiltersBase {
  projectId?: number;
  featureId?: number;
  showOnlyOpen?: boolean;
}

export interface IBacklogParams {
  clientId: string;
  epicId?: string;
  featureId?: string;
  userStoryId?: string;
}

export interface IClientZendeskRequest {
  statusId: number | string;
  ticketId: number | string;
  formId: number | string;
  ticketUrl?: string;
  title: string;
  severity: string;
  requester: string;
  requested: Date;
  lastUpdated: Date;
  statusCategory?: string;
  sprintIteration?: string;
}

export interface IRelease {
  releaseId: number;
  releaseSummary: string;
  releaseDate: string;
  releaseStatusName: string;
  createdOn: string;
  clientId: number;
  clientName: string;
  application: string;
  applicationId: number;
  clientProjectId?: number;
  clientProject?: string;
  reason?: string;
  releaseEndTime?: string;
  releaseTimeZone: number | string;
  clientApprovalEmailHasSent?: boolean;
  actualReleaseDate: string;
  actualReleaseEndDate: string;
}

export interface IUpcomingReleases {
  name: string;
  releaseDate: string;
}

export interface IOpenRequests {
  count: number;
  statusName: string;
}

export enum EntryType {
  Folder = 'Folder',
  File = 'File',
}
export interface IGraphEntry {
  name: string;
  createdDateTime: Date;
  lastModifiedDateTime: Date;
  id: string;
  path?: string;
  type: EntryType;
  fileUri: string;
}
export interface IZendeskTicketInfo {
  subject: string;
  description: string;
  module: string;
  createdAt: string;
  severity: number;
  emailAddresses: string;
  name: string;
  department: string;
  desiredTimeline: string;
  application: string;
  clientStakeholders: string;
  potentialLaunchDates: string;
  ticketFormId: number;
  actualResults: string;
  expectedResults: string;
  reproductionSteps: string;
  category: number;
  uploadedFileTokens: string[];
}

export interface IVerificationStep {
  id: number;
  name: string;
  order: number;
  isComplete: boolean;
}

export interface IReleaseStep {
  id: number;
  name: string;
  order: number;
  status: string;
}

export enum ReleaseStepStatuses {
  DONE = "Done",
  NOTDONE = "NotDone",
}

export enum ReleaseStatuses {
  NEW = "New",
  INTERNALPLANNING = "Internal Planning",
  CLIENTAPPROVAL = "Client Approval",
  RELEASING = "Releasing",
  RELEASED = "Released",
  VERIFYING = "Verifying",
  REMEDIATING = "Remediating",
  COMPLETE = "Complete",
}

export enum ApprovalType {
  DELIVERYLEADAPPROVED = "Delivery Lead Approved",
  CLIENTAPPROVED = "Client Approved",
  VERIFIED = "Verified",
  RELEASED = "Released",
  UNSET = 'Unset'
}
