/**
 * ValidationContext.tsx
 * Provides a React context for managing validation messages across the application for software releases.
 *
 * - `ValidationProvider`: Wraps around components to provide validation context.
 * - `useValidation`: Hook to access and update validation messages.
 * */

import React, { createContext, useContext, useState, FC, ReactNode, useMemo } from 'react';

interface ValidationContextProps {
  validationMessages: string[];
  setValidationMessages: React.Dispatch<React.SetStateAction<string[]>>;
  removeValidationMessage: (message: string) => void;
  setPageKey: (key: string) => void;
}

const ValidationContext = createContext<ValidationContextProps | undefined>(undefined);

export const ValidationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [pageKey, setPageKey] = useState<string>('default');

  const orders: { [key: string]: string[] } = {
    generalPage: ['Client', 'Application', 'Release Summary', 'Client Budget', 'Release Date', 'Release Time', 'Assigned Employee', 'Client Contact'],
    releasePage: [
      'Assigned Employee',
      'Assigned Employee: Engineering Manager or Delivery Lead',
      'Client Contact',
      'Release Date',
      'Release Time',
      'Release Contents',
      'Execution Steps',
      'Verification Steps'
    ]
  };

  const orderedValidationMessages = useMemo(() => {
    const predefinedOrder = orders[pageKey] || orders['generalPage'];
    return predefinedOrder.filter(item => validationMessages.includes(item));
  }, [validationMessages, pageKey]);

  // Use useMemo to ensure the context value is only recalculated when necessary
  const contextValue = useMemo(
    () => ({
      validationMessages: orderedValidationMessages,
      setValidationMessages,
      removeValidationMessage: (message: string) => {
        setValidationMessages(prevMessages => prevMessages.filter(msg => msg !== message));
      },
      setPageKey
    }),
    [orderedValidationMessages, setValidationMessages, setPageKey]
  );

  return <ValidationContext.Provider value={contextValue}>{children}</ValidationContext.Provider>;
};

export const useValidation = (): ValidationContextProps => {
  const context = useContext(ValidationContext);
  if (!context) {
    throw new Error('useValidation must be used within a ValidationProvider');
  }
  return context;
};
