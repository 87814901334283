import React, { FC } from 'react';
import { DialogActions, Button, Typography } from '@material-ui/core';
import { Modal } from '@shared/components/modals/Modal';
import { Loader } from '@shared/components/loader';
import { getClient } from '@shared/fetch';
import { ApprovalType } from '@shared/types';
import { useQuery } from 'react-query';
import { DateTimeInput } from '@shared/components/forms';

interface IReleaseApprovalModal {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  isMediumDown?: boolean;
  clientId: number | null;
  approvalType: ApprovalType;
  handleDeliveryLeadApproveSendEmail: () => void;
  handleClientApprovedSendEmail: () => void;
  handleVerifiedSendEmail: () => void;
  handleReleaseToVerifying: () => void;
  setEndDate: (val: Date | null) => void;
  setSelectedEndTime: (val: string) => void;
  endDate: Date | null;
  selectedEndTime: string;
}

export const ReleaseApprovalModal: FC<IReleaseApprovalModal> = ({
  isModalOpen,
  handleCloseModal,
  isMediumDown,
  clientId,
  approvalType,
  handleDeliveryLeadApproveSendEmail,
  handleClientApprovedSendEmail,
  handleVerifiedSendEmail,
  handleReleaseToVerifying,
  setEndDate,
  setSelectedEndTime,
  endDate,
  selectedEndTime
}) => {
  const { isLoading: isLoadingClientData, data: clientData } = useQuery(
    ['client', clientId],

    async () => await getClient(Number(clientId) ?? null),
    {
      notifyOnChangeProps: 'tracked',
      enabled: !!clientId
    }
  );

  const setTitle = () => {
    if (approvalType === ApprovalType.DELIVERYLEADAPPROVED) {
      return 'Send Client Approval Email';
    } else if (approvalType === ApprovalType.CLIENTAPPROVED) {
      return 'Approve Release';
    } else if (approvalType === ApprovalType.VERIFIED) {
      return 'Mark Release Complete';
    } else if (approvalType === ApprovalType.RELEASED) {
      return 'Approve Release';
    }
  };

  return (
    <Modal
      maxWidth='md'
      open={isModalOpen}
      onClose={handleCloseModal}
      title={setTitle()}
      customPaddingContent={isMediumDown ? '' : '0 1.5rem 1.5rem 1.5rem'}
    >
      {isLoadingClientData ? (
        <Loader position='centered' type='overlay' size='small' />
      ) : (
        <>
          {approvalType === ApprovalType.DELIVERYLEADAPPROVED && (
            <>
              <Typography>Do you want to send an email to {clientData?.name ?? 'the client'} to approve the release?</Typography>
              <DialogActions className='mt-2'>
                <Button onClick={handleCloseModal} color='primary'>
                  Close
                </Button>
                <Button onClick={handleDeliveryLeadApproveSendEmail} color='primary' variant='contained'>
                  Send
                </Button>
              </DialogActions>
            </>
          )}
          {approvalType === ApprovalType.CLIENTAPPROVED && (
            <>
              <Typography>Are you ready to send meetings and updates for the approved release?</Typography>
              <DialogActions className='mt-2'>
                <Button onClick={handleCloseModal} color='primary'>
                  Close
                </Button>
                <Button onClick={handleClientApprovedSendEmail} color='primary' variant='contained'>
                  Send
                </Button>
              </DialogActions>
            </>
          )}
          {approvalType === ApprovalType.RELEASED && (
            <>
              <Typography>Move release to Verifying stage?</Typography>
              <DialogActions className='mt-2'>
                <Button onClick={handleCloseModal} color='primary'>
                  Close
                </Button>
                <Button onClick={handleReleaseToVerifying} color='primary' variant='contained'>
                  Confirm
                </Button>
              </DialogActions>
            </>
          )}
          {approvalType === ApprovalType.VERIFIED && (
            <>
              <Typography>Are you ready to mark this release as complete and send release completed emails?</Typography>
              <DateTimeInput
                label='Ended at:'
                timeLabel='Selected End Time'
                date={endDate || null}
                time={selectedEndTime || ''}
                onDateChange={date => setEndDate(date)}
                onTimeChange={time => setSelectedEndTime(time)}
                className='mt-2'
              />
              <DialogActions className='mt-2'>
                <Button onClick={handleCloseModal} color='primary'>
                  Close
                </Button>
                <Button onClick={handleVerifiedSendEmail} color='primary' variant='contained' disabled={!endDate || !selectedEndTime}>
                  Send
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Modal>
  );
};
