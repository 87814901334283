import React, { FC, useState, useEffect } from 'react';
import format from 'date-fns/format';
import { makeStyles, Theme } from '@material-ui/core/styles';
// Components
import { Typography } from '@material-ui/core';
import { DatePicker } from '@shared/components/inputs';
// Types
import { IRateHistory } from '@shared/types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IHistoryEffectiveDateCellProps {
  cell: { value: any };
  row: { index: number };
  data: IRateHistory[];
  editing: number;
  updateMyData: (index: number, newData: { rate?: number; effectiveDate?: string | null }) => void;
  setDateValid: (isValid: boolean) => void;
  isNewDateValid: boolean;
}

export const HistoryEffectiveDateCell: FC<IHistoryEffectiveDateCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  data,
  editing,
  updateMyData,
  setDateValid,
  isNewDateValid
}) => {
  const classes = useStyles();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<string | null>(initialValue);
  const [invalidDateError, setInvalidDateError] = useState<string>('');

  // DON'T UPDATE HERE, OR WE WILL RENDER ON EVERY KEYSTROKE
  const onChange = (date: MaterialUiPickersDate) => {
    setValue(date ? date.toISOString() : null);

    // now validate
    let isValid = true;
    if (date === null) {
      isValid = false;
      setInvalidDateError(`New date required.`);
    } else {
      if (index !== 0 && date !== null) {
        if (new Date(date).setHours(0, 0, 0, 0) >= new Date(data[index - 1].effectiveDate as string).setHours(0, 0, 0, 0)) {
          isValid = false;
          setInvalidDateError(`New date must be before next effective date.`);
        }
      }
      if (index !== data.length - 1 && date !== null) {
        if (new Date(date).setHours(0, 0, 0, 0) <= new Date(data[index + 1].effectiveDate as string).setHours(0, 0, 0, 0)) {
          isValid = false;
          setInvalidDateError(`New date must be after previous effective date.`);
        }
      }
    }
    setDateValid(isValid);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, { effectiveDate: value });
  };

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(initialValue ? initialValue : null);
  }, [initialValue]);

  // reset when editing is false
  useEffect(() => {
    if (editing !== index) {
      setValue(data[index].effectiveDate);
    }
  }, [editing, index, data]);

  return editing === index && data[index].effectiveDate !== null ? (
    <DatePicker
      className={classes.datePicker}
      id='date-picker'
      value={value ? new Date(value) : null}
      onChange={onChange}
      onBlur={onBlur}
      error={!isNewDateValid}
      helperText={!isNewDateValid && invalidDateError}
    />
  ) : (
    <Typography variant='body1'>
      {value !== null
        ? format(new Date(value), 'MM/dd/YYYY', {
            useAdditionalWeekYearTokens: true
          })
        : 'Earliest'}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    marginTop: 0,
    marginBottom: 0
  }
}));
